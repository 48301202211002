import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const DateAndTimePicker = (props) => {

   const {
      handleChange,
      value,
      startDate,
      endDate,
      number
   } = props;

   return(
      <>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['MobileDateTimePicker']}>
            <DemoItem label="Mobile variant">
               <MobileDateTimePicker 
                  defaultValue={dayjs(startDate)} 
                  value={value === '' ? dayjs(startDate):  dayjs(value)}
                  minDate={dayjs(startDate)}
                  maxDate={dayjs(endDate)}
                  onChange={(newValue) => handleChange(newValue, number)}
                  className='date-and-time-picker'
               />
            </DemoItem>
            </DemoContainer>
         </LocalizationProvider>
      </>
   )
}
export default DateAndTimePicker;