import './header.scss';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserServices from "@services/user";
import ProfileImage from '@assets/images/profile-img.png';
import { ReactComponent as BackArrow } from "@assets/icons/Common/back-arrow.svg";
import Auth from "@services/auth";
import { getItem, setItem } from "@utils/getAndSetDataToStorage";
import { toast } from 'react-toastify';

const Header = ({ back, title, transparentBG, onHandleClick }) => {

  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  
  // const OnClickSignOut = () => {
  //   Auth.SignOut().then((response) => {
  //     localStorage.removeItem("x_army_auth_token");
  //     navigate("/sing-in")
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  // }


  useEffect(() => {
    const authToken = localStorage.getItem("x_army_auth_token");
    if(!authToken){
      localStorage.removeItem("x_army_auth_token");
      navigate("/sign-in")
    }
    else{
      const profile = getItem("user_profile", true);

      if(!profile){
        const userId = getItem("user_id");
        if(!userId) { navigate('/sign-in') }
        else{
          getUserProfile(userId, 'admin')
        }
      }
      else{
        setUserProfile(profile);
      }
    }
  }, [])
  
  const getUserProfile = async (id, role) => {
    await UserServices.getUser(id, role).then((response) => {
      const data = response.data;
      if(data.status === true){
        setItem("user_profile", JSON.stringify(data.data));
        navigate('/')
      }
      else{
        toast.error(data.message);
        localStorage.removeItem("x_army_auth_token")
      }
    })
    .catch((error) => {
      toast.error(error);
      console.log(error);
    });
  }

  return(
    <div className={`header ${transparentBG ? 'bg-transparent' : ''}`}>
      <div className={`header-container ${onHandleClick ? 'justify-content-between' : 'justify-content-center'}`}>
        {
          onHandleClick ?
          <button 
            className='back-button'
            onClick={onHandleClick}
          >
            <BackArrow />
          </button>
          :
          null
        }
        {
          title ? 
          <div className='header-title'> {title} </div>
          :
          <Link to='/' className='header-logo'> X Army </Link>
        }
        <div className={`header-profile-img-wrapper ${onHandleClick ? 'position-relative' : ''}`}>
          <img src={ProfileImage} alt='' />
        </div>
      </div>
    </div>
  )
}
export default Header;