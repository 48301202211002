import axios from "axios";
import config from "../config";

const campaigns = {};

const baseUrl = config.baseUrl;

campaigns.createCampaign = async (data) => {
   const url = `${baseUrl}/add-campaign`;
   const res = await axios({
      url,
      method : "POST",
      data,
   })

   return res;
}

campaigns.updateCampaign = async (data) => {
   const url = `${baseUrl}/update-campaign`;
   
   const res = await axios({
      url,
      method : "POST",
      data,
   })

   return res;
}

campaigns.campaignList = async (data) => {
   const url = `${baseUrl}/campaign-list`;

   const res = await axios({
      url,
      method: 'GET'
   })

   return res;
}

export default campaigns;