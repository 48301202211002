import "./index.scss"
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';
import UploadIcon from "@assets/icons/Common/upload-icon.svg";

const FileInput = (props) => {
   
   const { 
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      setQuestion, 
      deleteQuestion,
      editQuestion
   } = props;

   return(
      <>
         <div className='question-container' key={number}>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
               />
            </div>

            <div className='d-flex answer-container'>
               <label className="custom-file-input-wrapper d-block w-100">
                  <input 
                     type='file'
                     className='d-none'
                     accept=".pdf, .xlxs, .docs, .doc, .txt"
                     onChange={(e) => onChangeAnswer(e.target.files[0], number)}
                  />
                  <span className="file-upload-button d-flex align-items-center justify-content-start">
                     <img src={UploadIcon} alt='' />
                     <div className='label'>  { data?.answer ? data.answer.substring(0, 22) + '...' : 'Upload' } </div>
                  </span>
               </label>

               <button 
                  className={`edit-button position-relative ${!editQuestion ? 'active' : ''}`}
                  onClick={() => setQuestion(number)}
                  >
                  <Edit />
               </button>
               <button 
                  className='delete-button'
                  onClick={() => deleteQuestion(number)}
               >
                  <Delete />
               </button>
            </div>
         </div>
      </>
   )
}
export default FileInput;