import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import MeetingDetailCard from "@components/common/MeetingDetailCard";
import QuestionsWrapper from '@components/ProjectOnboardingDetails/QuestionsWrapper';
import { getItem, removeItem, setItem } from "@utils/getAndSetDataToStorage";
import CampaignService from "@services/campaign";
import { toast } from 'react-toastify';

const CampaignQuestions = () => {

  const navigate = useNavigate();
  const [campaignDetails, setCampaignDetails] = useState({});
  const [campaignQuestions, setCampaignQuestions] = useState([]);
  const [campaingAnswered, setCampaignAnswered] = useState([]);
  const [campDuration, setCampDuration] = useState([]);

  useEffect(() => {
    const camp_details = getItem('campaign_details', true);
    const campaing_answered = camp_details.questions.filter((obj) => obj.answer !== '' && obj.questionId !== 'no of tweets');
    const campaign_questions = camp_details.questions.filter((obj) => obj.answer === '' || obj.questionId === 'no of tweets');
    const camp_dur = camp_details.questions.find((obj) => obj.questionId === 'campaign duration');

    setCampDuration(camp_dur)
    setCampaignQuestions(campaign_questions);
    setCampaignAnswered(campaing_answered);
    setCampaignDetails(camp_details)
  }, [])

  const checkInputs = () => {
    const camp_answered = campaingAnswered.every((obj) => obj.answer !== '');
    const camp_questions = campaignQuestions.every((obj) => obj.answer !== '');
    if(camp_answered && camp_questions) return false
    else return true;
  }

  useEffect(() => {
    if(campaingAnswered.length > 0){
      const camp_dur = campaingAnswered.find((obj) => obj.questionId === 'campaign duration');
      setCampDuration((prevObj) => {
        const newObj = { ...prevObj };
        newObj.answer = camp_dur.answer;
        return newObj;
      });

    }
  }, [campaingAnswered]);

  const removeDataFromStorage = () => {
    removeItem("campaign_details");
    navigate("/campaign-list")
  }

  const updateCampaignDetails = async () => {

    const allCampQuestions = [...campaingAnswered, ...campaignQuestions];
    const campaign_details = {...campaignDetails}
    campaign_details.questions = [...allCampQuestions];
    setCampaignDetails(campaign_details)

    const data = {
      campaignId: campaignDetails.campaignId,
      campaignQuestions: JSON.stringify(allCampQuestions),
    }

    await CampaignService.updateCampaign(data).then((res) => {
      if(res.data.status){
        setItem("campaign_details", JSON.stringify(campaign_details))
        navigate('/recommended-influencers')
      }
      else{
        toast.error(res.data.message);
      }
    })
    .catch((e) => {
      const errors = e.response.data.message;
      toast.error(errors)
    })
  }

  return(
    <div className='main-container'>
      <Header 
        onHandleClick={removeDataFromStorage}
      />

      <div className='onboarding-details-page'>
        
        <MeetingDetailCard 
          sentOn={campaignDetails?.deepLinkSentOn}
          name={campaignDetails?.campaignName}
          meetingDate='24th Oct 2025'
          profileImg=''
        />
        
        <div className='title'>
          Marketing Questionnaire
        </div>

        <div className='br' />
        
        {
          campaingAnswered?.length > 0 &&
          <QuestionsWrapper 
            questions={campaingAnswered}
            setQuestion={setCampaignAnswered}
            title="Questions Answered"
          />
        }

        {
          campaignQuestions?.length > 0 &&
          <QuestionsWrapper 
            questions={campaignQuestions}
            setQuestion={setCampaignQuestions}
            title="Questions Pending"
            campaignDuration={campDuration}
          />
        }

        <button
          onClick={updateCampaignDetails}
          disabled={checkInputs()}
          className='common-button margin-top-30'
        >
          Proceed
        </button>
      </div>
    
    </div>
  )
}
export default CampaignQuestions;