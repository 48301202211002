import { Link } from 'react-router-dom';
import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import moment from "moment";
import { setItem } from "@utils/getAndSetDataToStorage";

const SearchProfileCard = ({ data, selectedTab }) => {
   const { name, id, deepLinkSentOn, onBoardingStatus, logo } = data;

   const saveData = () => {
      selectedTab === "Companies" ? setItem("project_id", id) : setItem("influencer_id", id);
   }
  
   const date = moment(deepLinkSentOn);
   const formattedDate = date.format('DD MMM YYYY');

   return(
      <Link 
         to={selectedTab === 'Companies' ? '/project-profile' : '/influencer-profile'} 
         className='onboarded-detail-card'
         onClick={saveData}
      >
         <div className='d-flex align-items-center'>
         <img src={logo ? logo : DummyProfileImg} alt='' className='logo' />
         <div>
            <div className='name'>
               {name}
            </div>
            <div className='date'>
               Onboarded - {formattedDate}
            </div>
         </div>
         </div>
      </Link>
   )
}
export default SearchProfileCard;