
const getYearsOptions = () => {
   const currentYear = new Date().getFullYear();

   const years = [];
   for (let year = 1991; year <= currentYear; year++) {
      const yearObject = { id: year, value: String(year) };
      years.push(yearObject);
   }
   return years;
}

const getDuration = () => {
   var currentDate = new Date();

   const endDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000);
   const date = [currentDate, endDate];
   return date;
}

export const projectQuestions = [
   {
      question: "What's the name of your project ? *",
      label: 'Project name',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: 'name of project',
   },
   {
      question: "Enter Company's Official Email ? *",
      label: 'Email Id',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "company's email"
   },
   {
      question: "Enter Your website URL",
      label: 'Website url',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "site url"
   },
   // {
   //    question: "Enter project type",
   //    label: 'Project Type',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'text',
   //    questionId: "project type"
   // },
   {
      question: "Tell us something about your project",
      label: 'About project',
      answer: "",
      disabledEdit: true,
      type: 'textarea',
      questionId: "about project"
   },
   // {
   //    question: "Enter your tagline",
   //    label: 'Tagline',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'text',
   //    questionId: "tagline"
   // },
   {
      question: "Connect your social accounts",
      label: 'Social Account',
      answer: "",
      disabledEdit: true,
      type: 'social-media-urls',
      questionId: "company's social urls",
      socialMediaUrls : [
         { name : "twitter", url: '' },
         { name : "youtube", url: '' },
         { name : "instagram", url: '' },
         { name : "facebook", url: '' },
      ]
   },
   {
      question: "Are you listed on any exchanges ?",
      label: 'Exchanges',
      answer: "",
      disabledEdit: true,
      type: 'boolean',
      questionId: "on exchanges listed"
   },
   {
      question: "What are you looking for ?",
      label: 'Almost there 😍',
      answer: "",
      disabledEdit: true,
      type: 'select',
      options: [{id: 1, value: 'Marketing'}, {id: 2, value: 'VCs'}, {id: 3, value: 'Strategic partnerships'}, {id: 4, value: 'All of the above'}, {id: 5, value: 'other-say what'}],
      questionId: "seek"
   },
   // {
   //    question: "When was your company established ?",
   //    label: "Company Established Year",
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'select',
   //    options: getYearsOptions(),
   //    questionId: "established year"
   // },
   // {
   //    question: "How much fund you have raised (in $)?",
   //    label: 'Fund Raised',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'text',
   //    questionId: "fund raised"
   // },
   // {
   //    question: "What is the valuation of your company (in $)?",
   //    label: 'Company Valuation',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'text',
   //    questionId: "company valuation"
   // },
   {
      question: "Upload your whitepaper",
      label: 'Upload Whitepaper',
      answer: "",
      disabledEdit: true,
      type: 'file',
      fileUploaded: false,
      questionId: "whitepaper"
   },
   {
      question: "Upload your pitchdeck",
      label: 'Pitchdeck',
      answer: "",
      disabledEdit: true,
      type: 'file',
      fileUploaded: false,
      questionId: "pitchdeck"
   },
   {
      question: "Do you have audit?",
      label: 'Audit',
      answer: "",
      disabledEdit: true,
      type: 'boolean',
      questionId: "have an audit"
   },
]


export const influencerQuestions = [
   {
      question: "Enter your name",
      label: 'name',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: 'influencer name',
   },
   {
      question: "Enter your Email",
      label: 'Email Id',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "influencer's email"
   },
   // {
   //    question: "Influencer type",
   //    label: 'Platform',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'select',
   //    options: [{id: 1, value: 'Twitter'}, {id: 2, value: 'Instagram'}, {id: 3, value: 'Linkedin'}, {id: 4, value: 'Youtube'}],
   //    questionId: "influencer platform"
   // },
   {
      question: "Share a brief about yourself. Include your background, interests, and any other details for your bio.",
      label: 'experience',
      answer: "",
      disabledEdit: true,
      type: 'textarea',
      questionId: "experience"
   },
   {
      question: "Provide the links to your active social media accounts.",
      label: 'Social Account',
      answer: "",
      disabledEdit: true,
      type: 'social-media-urls',
      questionId: "influencer's social urls",
      socialMediaUrls : [
         { name : "twitter", url: '' },
         { name : "youtube", url: '' },
         { name : "instagram", url: '' },
         { name : "facebook", url: '' },
      ]
   },
   {
      question: "Enter your twitter username",
      label: 'Username',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "twitter username"
   },
   // {
   //    question: "Upload your portfolio",
   //    label: 'Upload portfolio',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'file',
   //    fileUploaded: false,
   //    questionId: "portfolio"
   // },
   {
      question: "How would you like to get paid ?",
      label: 'Payment Method',
      answer: "",
      disabledEdit: true,
      type: 'select',
      options: [{id: 1, value: 'Stablecoins'}, {id: 2, value: 'Project tokens'}, {id: 3, value: 'Combination of both'}],
      questionId: "payment method"
   },
   // {
   //    question: "How many projects you have completed till now ?",
   //    label: 'Projects',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'text',
   //    questionId: "projects number"
   // },
   {
      question: "Choose your preferred communication channel for project-related discussions.",
      label: 'Communication Channel',
      answer: "",
      disabledEdit: true,
      type: 'select',
      options: [{id: 1, value: 'Email'}, {id: 2, value: 'WhatsApp'}, {id: 3, value: 'Telegram'}, {id: 4, value: 'Other'}],
      questionId: "communication channel"
   },
   {
      question: "Select your focus niche within the crypto space",
      label: 'niche crypto space',
      answer: "",
      disabledEdit: true,
      type: 'select',
      options: [{id: 1, value: 'DeFi'}, {id: 2, value: 'NFT'}, {id: 3, value: 'Altcoins'}, {id: 4, value: 'Lowcap Gems'}, {id: 5, value: 'TA'}, {id: 6, value: 'Other'}],
      questionId: "niche crypto space"
   },
   {
      question: "Enter the handle or alias you are known by online.",
      label: 'alias',
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "alias"
   },
   // {
   //    question: "Enter Your website URL",
   //    label: 'Website url',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'text',
   //    questionId: "site url"
   // },
   // {
   //    question: "Enter your skills",
   //    label: 'Your skills',
   //    answer: "",
   //    disabledEdit: true,
   //    type: 'textarea',
   //    questionId: "skills"
   // },
   {
      question: "What is your price for 1-3 tweets ? (in $)",
      label: "Tweet's prices",
      answer: "",
      disabledEdit: true,
      type: 'number',
      questionId: "tweet price for 1-3",
   },
   {
      question: "What is your price for 4-6 tweets ? (in $)",
      label: "Tweet's prices",
      answer: "",
      disabledEdit: true,
      type: 'number',
      questionId: "tweet price for 4-6",
   },
   {
      question: "What is your price for 8-10 tweets ? (in $)",
      label: "Tweet's prices",
      answer: "",
      disabledEdit: true,
      type: 'number',
      questionId: "tweet price for 8-10",
   }
]




export const CampaignQuestions = [
   {
      question: "What's the name of your Campaign ?",
      label: "Camapign name",
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "camapign name",
   },
   {
      question: "What is your marketing budget for this campaign? (in $)",
      label: "Budget for campaign",
      answer: "",
      disabledEdit: true,
      type: 'number',
      questionId: "budget for campaign",
   },
   {
      question: "What is the main objective & goals of the campaign ?",
      label: "Goal of campaign",
      answer: "",
      disabledEdit: true,
      type: 'textarea',
      questionId: "goal of campaign",
   },
   {
      question: "Are there any specific influencers or partners you would like to collaborate with?",
      label: "Specific influencer",
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "specific influencer",
   },
   {
      question: "What type of influencers or partners are you looking for ?",
      label: "Influencer Type",
      answer: "answer received",
      disabledEdit: true,
      type: 'tabs',
      questionId: "influencer Type",
      options: [{id: 1, value: 'DeFi', selected: true}, {id: 2, value: 'DEX', selected: false}, {id: 3, value: 'Air Drop', selected: false}, {id: 4, value: 'Launch', selected: false}, {id: 5, value: 'DEX', selected: false}, {id: 6, value: 'DAO', selected: false}, {id: 7, value: 'Other', selected: false}]
   },
   {
      question: "Campaign duration :",
      label: "campaign duration",
      answer: getDuration(),
      disabledEdit: true,
      type: 'date-range',
      questionId: "campaign duration",
      firstLabel: "Campaign starting date",
      secondLabel: 'Campaign ending date'
   },
   {
      question: "No of tweets",
      label: "Tweets",
      answer: 1,
      disabledEdit: true,
      type: 'tweet content',
      questionId: "no of tweets",
      content: [{ postDate: "", postTime: "", postDateAndTime: '', document: "", content: "", platform: "twitter", threads: [] }],
      options: [{id: 1, value: 1, label: '1 Tweet'}, {id: 2, value: 2, label: '2 Tweets'}, {id: 3, value: 3, label: '3 Tweets'}, {id: 4, value: 4, label: '4 Tweets'}, {id: 5, value: 5, label: '5 Tweets'}, {id: 6, value: 6, label: '6 Tweets'}, {id: 7, value: 7, label: '7 Tweets'}, {id: 8, value: 8, label: '8 Tweets'}, {id: 9, value: 9, label: '9 Tweets'}, {id: 10, value: 10, label: '10 Tweets'}], 
   },
]

export const CampaignQuestions2 = [
   {
      question: "What's the name of your Campaign ?",
      label: "Camapign name",
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "camapign name",
   },
   {
      question: "What is your marketing budget for this campaign? (in $)",
      label: "Budget for campaign",
      answer: "",
      disabledEdit: true,
      type: 'number',
      questionId: "budget for campaign",
   },
   {
      question: "What is the main objective & goals of the campaign ?",
      label: "Goal of campaign",
      answer: "",
      disabledEdit: true,
      type: 'textarea',
      questionId: "goal of campaign",
   },
   {
      question: "Are there any specific influencers or partners you would like to collaborate with?",
      label: "Specific influencer",
      answer: "",
      disabledEdit: true,
      type: 'text',
      questionId: "specific influencer",
   },
   {
      question: "What type of influencers or partners are you looking for ?",
      label: "Influencer Type",
      answer: "answer received",
      disabledEdit: true,
      type: 'tabs',
      questionId: "influencer Type",
      options: [{id: 1, value: 'DeFi', selected: true}, {id: 2, value: 'DEX', selected: false}, {id: 3, value: 'Air Drop', selected: false}, {id: 4, value: 'Launch', selected: false}, {id: 5, value: 'DEX', selected: false}, {id: 6, value: 'DAO', selected: false}, {id: 7, value: 'Other', selected: false}]
   },
   {
      question: "Campaign duration :",
      label: "campaign duration",
      answer: getDuration(),
      disabledEdit: true,
      type: 'date-range',
      questionId: "campaign duration",
      firstLabel: "Campaign starting date",
      secondLabel: 'Campaign ending date'
   },
   {
      question: "No of tweets",
      label: "Tweets",
      answer: 1,
      disabledEdit: true,
      type: 'tweet content',
      questionId: "no of tweets",
      content: [{ postDate: "", postTime: "", postDateAndTime: '', document: "", content: "", platform: "twitter", threads: [] }],
      options: [{id: 1, value: 1, label: '1 Tweet'}, {id: 2, value: 2, label: '2 Tweets'}, {id: 3, value: 3, label: '3 Tweets'}, {id: 4, value: 4, label: '4 Tweets'}, {id: 5, value: 5, label: '5 Tweets'}, {id: 6, value: 6, label: '6 Tweets'}, {id: 7, value: 7, label: '7 Tweets'}, {id: 8, value: 8, label: '8 Tweets'}, {id: 9, value: 9, label: '9 Tweets'}, {id: 10, value: 10, label: '10 Tweets'}], 
   },
]

export const CampaignContent = {
   postDate: "",
   postTime: "",
   postDateAndTime: '',
   document: "",
   content: "",
   platform: "twitter",
   threads: []
}
