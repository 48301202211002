import { useState } from 'react';
import "./campaignsDetails.scss";
import BannerImg from "@assets/icons/Campaigns/campaign.png";
import { ReactComponent as BackArrow } from "@assets/icons/Common/back-arrow.svg";
// import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
// import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
import SelectedInfluencerCard from "@components/common/InfluencerDetailCard";
import CrossIcon from "@assets/icons/Common/cross-circle.png";
import TerminationModal from "@components/CampaignsDetails/terminationModal";


const selectedInfluencers = [
  {
    name: 'John Doe',
    mainPlatform: 'twitter',
    budget: "4200",
    image: "https://picsum.photos/200/300"
  },
  {
    name: 'John Doe',
    mainPlatform: 'twitter',
    budget: "4200",
    image: "https://picsum.photos/200/300"
  },
]

const CampaignsDetails = () => {

  const [showTerminateModal, setShowTerminateModal] = useState(false);
  const [terminationReason, setTerminationReason] = useState('');
  const [terminated, setTerminated] = useState(false);

  return(
    <div className='campaigns-details position-relative'>

      <TerminationModal 
        showModal={showTerminateModal}
        setModal={setShowTerminateModal}
        reasonForTermination={terminationReason}
        setReasonForTermination={setTerminationReason}
        setTermination={setTerminated}
      />

      <div className='banner-wrapper'>
        <img src={BannerImg} alt='' className='banner-img' />
        <div className='banner-details d-flex flex-column justify-content-between h-100'>
          <button className='back-button'>
            <BackArrow />
          </button>

          <div>
            <div className='title'>
              Nike Crypto Kicks
            </div>

            <div className='d-flex align-items-center campaign-date-breket'>
              10th April 2023 - 18th April 2023
            </div>

            <div className='budget'>
              $8,800
            </div>
          </div>
        </div>
      </div>


      <div className='campaigns-details-wrapper'>
        {
          terminated && 
          <>
            <div className='terminate-reason-title'>
              Reason for Termination
            </div>
            <div className='termination-reason'>
              {terminationReason}
            </div>
          </>
        }
        <div className='title'>
          Goal of the Campaign
        </div>
        <div className='desc'>
          Davis Stanton is a Bitcoin analyst most popular for his forecasts and analyses. This New Zealand-born commentator and speculator is one of the biggest voices in the crypto space because of his predictions using in-house indicators he created himself. On his feed, he often tweets the latest charts along with his thoughts and readings of the same.Willy Woo is a Bitcoin analyst most popular for his forecasts and analyses. 
        </div>

        <div className='title'>
          Campaign Platforms
        </div>

        <div className='d-flex'>
          <div className='platform-wrapper'>
            <Twitter />
          </div>
          <div className='platform-wrapper'>
            <Youtube />
          </div>
        </div>
        {
          !terminated &&
          <>
            <div className='title'>
              2 Influencers
            </div>

            {
              selectedInfluencers.map((influencer, index) => (
                <SelectedInfluencerCard 
                  key={index}
                  data={influencer}
                  showCalendarLink={true}
                />
              ))
            }

            <button 
              className='termination-button'
              onClick={() => setShowTerminateModal(true)}
            >
              <img src={CrossIcon} alt='' />
              Terminate Project
            </button>
          </>
        }
      </div>

    </div>
  )
}
export default CampaignsDetails;