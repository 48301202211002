import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import AddQuestionModal from "@components/common/AddQuestionModal";
import InfulencerServices from "@services/influencer";
import { v4 as uuid } from 'uuid';
import moment from "moment";
import { ReactComponent as AddIcon } from '@assets/icons/QuestionsForProject/add-icon.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/QuestionsForProject/link.svg';
import { setItem, getItem, removeItem } from "@utils/getAndSetDataToStorage";
import { influencerQuestions } from "@config/Questions";
import QuestionsContainer from "@components/common/QuestionsContainer";
import { toast } from 'react-toastify';

const QuestionForProject = () => {

  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState(influencerQuestions);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const unique_id = uuid();

  useEffect(() => {
    let question = getItem("influencer_questions", true)
    if(question !== undefined) setQuestionList(question)
  }, [])


  const submitQuestions = async () => {

    const name = questionList.find((obj) => obj.questionId === 'influencer name');
    const email = questionList.find((obj) => obj.questionId === "influencer's email");
    const socialMediaIndex = questionList.findIndex((obj) => obj.type === 'social-media-urls');
    const urls = questionList[socialMediaIndex].socialMediaUrls;
    const isFilled = urls.every((obj) => obj.url !== '');
    if(isFilled) questionList[socialMediaIndex].answer = 'Social URLs received';

    let now = moment();
    const uniqueId = unique_id;

    const data = {
      name : name.answer,
      email: email.answer,
      questions: JSON.stringify(questionList),
      influencerId : uniqueId,
      deepLinkSentOn: now.format(),
    }
    
    await InfulencerServices.addQuestions(data).then((res) => {
      const data = res.data;
      if(data.status === true){
        const deepLink = `localhost:3000/sign-up?id=${uniqueId}`
        setItem("deep_link", deepLink)
        setItem("influencer_questions", JSON.stringify(questionList))
        navigate("/deep-link");
      }
      else{
        toast.error(data.message);
      }
    }).catch((e) => {
      const errors = e.response.data.message;
      toast.error(data.message);
    })
  }
  
  const checkInputs = () => {
    const name = questionList.find((obj) => obj.questionId === 'influencer name');
    const email = questionList.find((obj) => obj.questionId === "influencer's email");

    if(name.answer === '' || email.answer === ''){
      return true;
    }
    else{
      return false;
    }
  }


  const addQuestion = (questionDetails) => {
    const list = [...questionList];
    list.push(questionDetails);
    setQuestionList(list);
  }

  const removeItemFromStorage = () => {
    removeItem("influencer_questions");
    removeItem("deep_link");
    navigate("/")
  }

  return(
    <div className='main-container'>
      <Header 
        onHandleClick={removeItemFromStorage}
      />

      <AddQuestionModal 
        show={showAddQuestionModal}
        setModal={setShowAddQuestionModal}
        handleAddQuestion={addQuestion}
      />
      
      <div className='project-questions'>
        <div className='d-flex align-items-center justify-content-between add-question-container'>
          <div className='title'>
            Questions for the Influncer
          </div>
          <button
            className='add-question-button'
            onClick={() => setShowAddQuestionModal(true)}
          >
            <AddIcon />
            Add
          </button>
        </div>

        <div className='questions'>
          <QuestionsContainer 
            questions={questionList}
            setQuestions={setQuestionList}
          />
        </div>

        <button
          className='common-button generate-link-button'
          onClick={submitQuestions}
          disabled={checkInputs()}
        >
          <LinkIcon />
          Generate Link
        </button>
      </div>
    </div>
  )
}
export default QuestionForProject;