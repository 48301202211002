// import CheckIcon from '@static/'
import {ReactComponent as UpcomingIcon} from "@static/assets/icons/Common/upcoming.svg";
import {ReactComponent as CheckIcon} from "@static/assets/icons/Common/check-circle.svg";
import {ReactComponent as BadgeCheck} from "@static/assets/icons/Common/badge-check.svg";
import {ReactComponent as Terminated} from "@static/assets/icons/Common/badge-check.svg";
import "./index.scss"

const Status = ({ status, icon, customClass, text }) => {

   return(
      <>
         {
            status === 'pending' &&
            <div className={`status-badge pending ${customClass ? customClass : '' }`}>
               {icon ? <UpcomingIcon /> : null }
               {text ? text : 'Pending'}
            </div>   
         }
         {
            status === 'completed' &&
            <div className={`status-badge completed ${customClass ? customClass : '' }`}>
               {icon ? <BadgeCheck /> : null }
               Completed
            </div> 
         }
         {
            status === 'success' &&
            <div className={`status-badge success ${customClass ? customClass : '' }`}>
               {icon ? <CheckIcon /> : null }
               {text ? text : 'accepted'}
            </div> 
         }
         {
            status === 'terminated' &&
            <div className={`status-badge terminated ${customClass ? customClass : '' }`}>
               {icon ? <Terminated /> : null }
               {text ? text : 'Terminated'}
            </div> 
         }
         {
            status === 'active' &&
            <div className={`status-badge success ${customClass ? customClass : '' }`}>
               {icon ? <BadgeCheck /> : null }
               {text ? text : 'Active'}
            </div> 
         }
      </>
   )
} 
export default Status;