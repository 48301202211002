import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut  } from "firebase/auth";
import firebaseConfig from "./firebase";


const auth = {};
const app = initializeApp(firebaseConfig);


auth.login = (email, password) => {
   return new Promise((resolve, reject) =>{
      const auth = getAuth();
         signInWithEmailAndPassword(auth, email, password)
         .then((userCredential) => {
            const user = userCredential.user;
            localStorage.setItem("x_army_auth_token", userCredential._tokenResponse.idToken);
            resolve(user);
         })
         .catch((error) => {
            console.log(error);
            // const errorCode = error.code;
            const errorMessage = error.message;
            reject(errorMessage);
         });      
   })
}

auth.SignOut = async () => {
   return new Promise((resolve, reject) => {
      const auth = getAuth();
         signOut(auth).then(() => {
            resolve(true)
         }).catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message;
            reject(errorMessage);
         });
   })
}
export default auth;