import "./index.scss";
import { useState } from "react";
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';

const CustomSelect = (props) => {

   const {
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      setQuestion,  
      deleteQuestion,
      editQuestion
   } = props;

   const [showOption, setShowOption] = useState(false);

   return(
      <>
         <div className='question-container' key={number}>
            <div className='d-flex'>
              <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
               />
            </div>

            <div className='d-flex answer-container'>
               <div className='custom-select-wrapper'>
                  <div 
                     className='selected-value-wrapper'
                     onClick={() => setShowOption(!showOption)}
                  >
                     <div>{data.answer ? data.answer : 'Select'}</div>
                     {/* <img src={DownCaret} alt='' /> */}
                  </div>

                  <div className={`option-wrapper ${showOption ? 'show-option' : ''}`}>
                     {data.options !== null && data.options.map((option, i) => ( 
                        <div
                           key={i}
                           className='option'
                           onClick={() => {onChangeAnswer(option.value, number); setShowOption(false)}}
                        > 
                           { option.value } 
                        </div>
                     ))}
                  </div>
               </div>
               
               <button 
                  className={`edit-button position-relative ${!editQuestion ? 'active' : ''}`}
                  onClick={() => setQuestion(number)}
                  >
                  <Edit />
               </button>
               <button 
                  className='delete-button'
                  onClick={() => deleteQuestion(number)}
               >
                  <Delete />
               </button>
            </div>
         </div>
      </>
   )
}
export default CustomSelect;