import "./index.scss";

const TitleContainer = ({ title }) => {

  return(
    <div className='title-container'>
      {title}
    </div>
  )
}
export default TitleContainer;