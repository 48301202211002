import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';

const MultipleSelectTabs  = (props) => {

   const { 
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      setQuestion, 
      deleteQuestion,
      editQuestion
   } = props;

   return(
      <>
         <div className='question-container'>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
                  disabled={editQuestion}
               />
            </div>

            <div className='d-flex answer-container align-items-center'>
               <div className='tabs-container w-100'>
                  {
                     data?.options.map((item, ind) => (
                        <div 
                           className={`tab ${item.selected ? 'active' : ''}`}
                           key={`tab-${ind}`}
                           onClick={() => onChangeAnswer(item.value, number, ind)}
                        >
                           {item.value}
                        </div>
                     ))
                  }
               </div>

               <button 
                  className='delete-button'
                  onClick={() => deleteQuestion(number)}
               >
                  <Delete />
               </button>
            </div>
         </div>
      </>
   )
}
export default MultipleSelectTabs;