import ProjectService from "@services/project";

const downloadFile = async (filename, name) => {
  try {
    const response = await ProjectService.downloadFile(filename)

    const contentDisposition = response.headers['content-disposition'];
    let downloadFilename = name; // Default filename in case the server doesn't provide one

    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches !== null && matches[1]) {
        downloadFilename = matches[1].replace(/['"]/g, '');
      }
    }

    // Create a blob URL to simulate a download link
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const downloadUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element and click on it to initiate the download
    const tempLink = document.createElement('a');
    tempLink.href = downloadUrl;
    tempLink.setAttribute('download', downloadFilename);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    // Clean up the blob URL to free up memory
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    // Handle any errors that occur during the download
    console.error('Error downloading the file:', error);
  }
};
export default downloadFile;