import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import Status from "@components/common/Status";

const campaignStatusDetails = ({ data }) => {

   const { profileImg, name, campaignRange, status } = data;

   return(
      <div className='pending-campaign-card-wrapper'>
         <img src={profileImg ? profileImg : DummyProfileImg} alt='' className='company-logo' />

         <div>
            <div className='name'> {name} - Onboarding </div>
            <div className='date'> {campaignRange} </div>
            <Status 
               status={status}
               customClass='font-600'
            />
         </div>
      </div>
   )
}
export default campaignStatusDetails;