import "./index.scss";

const EditableQuestionAnswer = ({ data, number, changeQuestion, changeAnswer, edit }) => {

   return(
      <div className='d-flex editable-question-wrapper'>
         <div className='number'>{number + 1}.</div>
         <div className='w-100'>
            <input
               type='text' 
               className='question margin-bottom-9'
               value={data.question}
               onChange={(e) => changeQuestion(e.target.value, number, 'question')}
               disabled={edit}
            />
            <textarea
               type='text' 
               className='answer'
               value={data.answer}
               onChange={(e) => changeQuestion(e.target.value, number, 'answer')}
               disabled={edit}
            />
         </div>
      </div>
   )
}
export default EditableQuestionAnswer;