import { useState } from 'react'; 
import CrossIcon from "@static/assets/icons/Common/close-icon.png";
import Close from "@assets/icons/Common/close-icon.png";

const AddQuestionModal = ({ show, setModal, handleAddQuestion, withCampaign }) => {

   const [question, setQuestion] = useState('');
   const [label, setLabel] = useState('');
   const [questionType, setQuestionType] = useState('text');
   const [options, setOptions] = useState([{id : 1, value: ''}]);
   const [accept, setAccept] = useState(['.pdf', '.png', '.xlxs']);
   const [questionFor, setQuestionFor] = useState("")
   
   const checkInputs = () => {
      if(question === '' || 
         label === '' || 
         questionType === '' || 
         (questionType === 'select' && options[0].value === '') ||
         (questionType === 'file' || accept.length < 1)
      ){
         return true;
      }
   }

   const addOption = () => {
      const opt = [...options];
      opt.push({id : opt.length + 1, value: ''});
      setOptions(opt);
   }

   const handleChangeOption = (value, ind) => {
      const opt = [...options];
      options[ind].value = value;
      setOptions(opt);
   }

   const handleRemoveOption = (ind) => {
      const opt = [...options];
      opt.splice(ind, 1);
      setOptions(opt);
   }

   const addQuestion = () => {
      const questionDetails = {
         question: question,
         label: label,
         answer: "",
         disabledEdit: true,
         type: questionType,
         options: questionType === 'select' ? options : null
      }
      const forCamapign = questionFor === 'campaign' ? true : false;
      handleAddQuestion(questionDetails, forCamapign);
      setModal(false);
   }

   return(
      <>
         <div className={`custom-modal add-question-modal ${show ? 'show-modal' : ''}`}>
            <div className='modal-card-wrapper'>
               <div className='d-flex align-items-center justify-content-between'>
                  <div className='title'>Question details</div>

                  <button
                     type='button'
                     className='close-modal-button'
                     onClick={() => setModal(false)}
                  >
                     <img src={Close} alt='' />
                  </button>
               </div>

               {
                  withCampaign &&
                  <>
                     <label className='label'>Question For</label>
                     <select className='question-type-select' onChange={(e) => setQuestionFor(e.target.value)}>
                        <option value='onboarding' defaultValue> Onboarding </option>
                        <option value='campaign'> Campaign </option>
                     </select> 
                  </>
               }

               <label className='label'>What is your question?</label>
               <input 
                  type='text'
                  className='text-input'
                  onChange={(e) => setQuestion(e.target.value)}
                  value={question}
                  placeholder='Enter question'
               />

               <label className='label'>Please enter short label</label>
               <input 
                  type='text'
                  className='text-input'
                  onChange={(e) => setLabel(e.target.value)}
                  value={label}
                  placeholder='Enter short label'
               />

               <label className='label'>Select question input Type</label>
               <select className='question-type-select' onChange={(e) => setQuestionType(e.target.value)}>
                  <option value='text' defaultValue> Text </option>
                  <option value='select'> Select </option>
                  <option value='textarea'> Long Answer </option>
                  <option value='boolean'> Yes / No &nbsp; ( True / False ) </option>
                  {/* <option value='file'> File </option> */}
               </select> 

               {
                  questionType === 'select' &&
                  <>
                     <label className='label'>Add option for select</label>
                     {
                        options.map((item, ind) => (
                           <div className='position-relative'>
                              <input 
                                 type='text'
                                 className='text-input add-option-input'
                                 value={item.value}
                                 key={item.id}
                                 onChange={(e) => handleChangeOption(e.target.value, ind)}
                              />
                              {ind !== 0 &&
                                 <div className='remove-option' onClick={() => handleRemoveOption(ind)}>
                                    <img src={CrossIcon} alt='' />
                                 </div>
                              }
                           </div>
                        ))
                     }
                     <button className='add-option-button' onClick={addOption}>Add option</button>
                  </>
               }

               {
                  questionType === 'file' &&
                  <>

                  </>
               } 

               <button 
                  className='common-button add-question-button' 
                  onClick={addQuestion}
                  disabled={checkInputs()}
               >
                  Add Question
               </button>         
            </div>
         </div>
      </>
   )
}
export default AddQuestionModal;