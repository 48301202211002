import "./index.scss";
import { Link } from 'react-router-dom';
import Header from "@components/common/Header";
import { ReactComponent as Success } from '@assets/icons/DeepLink/success.svg';

const ProposalSent = () => {

  return(
    <div className="main-container">
      <Header />
      <div className='proposal'>
        <div></div>

        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Success />

          <div className='title'>
            Proposal Sent
          </div>

          <div className='desc'>
            An email has been sent to the company and influencer . You can check the status of the case on the Proposals Sent section.
          </div>
        </div>

        <Link
          to='/'
          className='common-button'
        >
          Back Home
        </Link>
      </div>
    </div>
  )
}
export default ProposalSent;