import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import ManageProjectOnboarding from '@components/ManageOnboarding/ManageProjectOnboarding';
import ProjectService from "@services/project";
import { removeItem } from "@utils/getAndSetDataToStorage";
import { toast } from 'react-toastify';

const Tabs = [ 'Companies Onboarding', 'Influencer Onboarding' ];

const ManageOnboarding = () => {

  const navigate = useNavigate();
  const [tab, setTab] = useState('Companies Onboarding');
  const [projects, setProjects] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [loading, setLoading] = useState(false)

  const getProjectsAndInfluencers = async () => {
    setLoading(true)
    try{
      const status = 'pending,answer-received';
      const type = 'projects,influencers';
      const res = await ProjectService.getProjectAndInfluencerListByStatus(status, type);
      if(res.status === 200){
        const projectsList = res.data.projectsList;
        const influencerList = res.data.influencerList;
        setProjects(projectsList);
        setInfluencer(influencerList);
      }
    }
    catch(error){
      toast.warning(error)
    }
    setLoading(false)
  }


  useEffect(() => {
    getProjectsAndInfluencers();
  }, [])

  const removeDataFromStorage = () => {
    removeItem("project_onboarding_details");
    removeItem("onboarding_influencer_details");
    removeItem("project_id");
    removeItem("influencer_id");
    navigate("/");
  }

  return(
    <div className='main-container'>
      <Header 
        title='Manage Onboarding'
        onHandleClick={removeDataFromStorage}
      />
      <div className='manage-onborarding'>
        <div className='manage-onborarding-tabs'>
          {
            Tabs.map((item, ind) => (
              <div 
                key={ind}
                className={`tab ${item === tab ? 'active' : ''}`}
                onClick={() => setTab(item)}
              > 
                {item} 
              </div>
            ))
          }
        </div>
        
        {
          tab === 'Companies Onboarding' ? 
          projects.length > 0 ? projects.map(( item, ind ) => (
            <ManageProjectOnboarding
              data={item}
              key={ind}
              selectedTab={tab}
            />
          )) : <div className='not-found-text'>Projects Not Found</div>
          :
          influencer.length > 0 ? influencer.map(( item, ind ) => (
            <ManageProjectOnboarding
              data={item}
              key={ind}
              selectedTab={tab}
            />
          )) : <div className='not-found-text'>Influencers Not Found</div>
        }
      </div>
    </div>
  )
}
export default ManageOnboarding;