import React from 'react';
import Facebook from "@assets/icons/Common/facebook.svg";
import Instagram from "@assets/icons/Common/instagram.svg";
import Twitter from "@assets/icons/Common/twitter.svg";
import LinkedIn from "@assets/icons/Common/linkedIn.svg";
import Youtube from "@assets/icons/Common/youtube.svg";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
// import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
// import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
// import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
// import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";

const PrfileSocialMediaLink = ({socialMediaUrls}) => {

   return(
      <div className='socials d-flex align-items-center'>
         {
         socialMediaUrls.map((item, ind) => (
            <React.Fragment key={ind}>
               {item.name === 'facebook' && 
               <a href={`https://${item.url}`} target='_blank' rel="noreferrer" className='socials-links'>
                  <img src={Facebook} alt='' />
               </a>
               }
               {item.name === 'twitter' &&
               <a href={`https://${item.url}`} target='_blank' rel="noreferrer" className='socials-links'>
                  <img src={Twitter} alt='' />
               </a>
               }
               {item.name === 'instagram' &&
               <a href={`https://${item.url}`} target='_blank' rel="noreferrer" className='socials-links'>
                  <img src={Instagram} alt='' />
               </a>
               }
               {item.name === 'linkedin' &&
               <a href={`https://${item.url}`} target='_blank' rel="noreferrer" className='socials-links'>
                  <img src={LinkedIn} alt='' />
               </a>
               }
               {item.name === 'youtube' &&
               <a href={`https://${item.url}`} target='_blank' rel="noreferrer" className='socials-links'>
                  <img src={Youtube} alt='' />
               </a>
               }
            </React.Fragment>
         ))
         }
      </div>
   )
}
export default PrfileSocialMediaLink;