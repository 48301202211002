import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import moment from "moment";
import Status from "@components/common/Status";

const InfluencerOnboardingDetails = ({ data }) => {

   const { sentOn, name, meetingDate, profileImg, onBoardingStatus } = data;

   const formattedDate = (value) => {
      const date = moment(value);
      const formattedDate = date.format('DD MMM YYYY');
      return formattedDate;
   }

  return(
    <div className='onboarding-company-details'>
      <img src={profileImg ? profileImg : DummyProfileImg} alt='' className='company-logo' />

      <div>
        <div className='name'> {name} - Onboarding </div>
        <div className='date'> Oboarding req Sent on - {formattedDate(sentOn)} </div>
        <Status 
          status={onBoardingStatus}
        />
      </div>
    </div>
  )
}
export default InfluencerOnboardingDetails;