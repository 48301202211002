import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';

const CompanyDetails = ({ logo, name, onBoardDate}) => {

  return(
    <div className='company-details'>
      <img src={logo ? logo : DummyProfileImg} alt={name} className='logo' />

      <div>
        <div className='name'> {name} - Marketing Campaign </div>
        <div className='onboard-date'> Onboarded On - {onBoardDate} </div>
      </div>
    </div>
  )
}
export default CompanyDetails;