import "./index.scss";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '@components/common/Header';
import InfluencerCard from '@components/RecommendedInfluencers/influncerCard.jsx';
import SearchAndFilterBar from "./searchAndFilterBar";
import InfluencerServices from "@services/influencer";
import { toast } from 'react-toastify';

// const influencers = [
//   {
//     profileImage: 'https://picsum.photos/200',
//     name: 'John Doe',
//     influncerType : "twitter",
//     rating: 4.5,
//     combinedReach: "408k",
//     engagement: "24%",
//     project: "48",
//   },
//   {
//     profileImage: 'https://picsum.photos/200',
//     name: 'John Doe',
//     influncerType : "twitter",
//     rating: 4.5,
//     combinedReach: "408k",
//     engagement: "24%",
//     project: "48",
//   },
//   {
//     profileImage: 'https://picsum.photos/200',
//     name: 'John Doe',
//     influncerType : "twitter",
//     rating: 4.5,
//     combinedReach: "408k",
//     engagement: "24%",
//     project: "48",
//   },
//   {
//     profileImage: 'https://picsum.photos/200',
//     name: 'John Doe',
//     influncerType : "twitter",
//     rating: 4.5,
//     combinedReach: "408k",
//     engagement: "24%",
//     project: "48",
//   },
//   {
//     profileImage: 'https://picsum.photos/200',
//     name: 'John Doe',
//     influncerType : "twitter",
//     rating: 4.5,
//     combinedReach: "408k",
//     engagement: "24%",
//     project: "48",
//   },
// ]

const RecommendedInfluencers = () => {

  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [influencerList, setInfluencerList] = useState([]);

  useEffect(() => {
    getInfluencerList()
  }, [])

  const getInfluencerList = async () => {
    try {
      await InfluencerServices.influencerList().then((res) => {
        const data = res.data;
        if(data.status){
          const influencers = data.response.influencers;
          console.log(influencers)
          setInfluencerList(influencers)
        }
        else{
          toast.warning(data.message);
        }
      })
    } catch (error) {
      console.log(error)
      toast.warning(error);
    }
  }

  const handleAddInfluencer = (item) => {
    const list = [...selectedInfluencers];
    list.push(item);
    setSelectedInfluencers(list);
  }
  
  return(
    <div className='main-container'>
      <Header />
      <div className='recommanded-influncers'>
        <SearchAndFilterBar />
        
        {
          influencerList.map((item, key) => (
            <InfluencerCard 
              key={key}
              details={item}
              onAddInfluencer={handleAddInfluencer}
            />
          ))
        }

        <div className='next-button-container'>
          <Link
            to='/review-proposal'
            className='common-button next-button'
          >
            Next
          </Link>
        </div>
      </div>
    </div>
  )
}
export default RecommendedInfluencers;