import "./index.scss";

const Tabs = [ 'Active', 'Pending','Upcoming', 'Completed', 'Terminated' ];

const StatusTabs = ({ activeTab, setTab }) => {

  return(
    <div className="status-tabs">
      {
        Tabs.map((tab, index) => (
          <div 
            className={`tab ${tab === activeTab ? 'active-tab' : ''}`}
            key={index}
            onClick={() => setTab(tab)}
          >
            {tab}
          </div>
        ))
      }
    </div>
  )
}
export default StatusTabs;