import axios from "axios";
import { toast } from 'react-toastify';

axios.interceptors.request.use(function (config) {
   config.headers["auth-token"] = localStorage.getItem("x_army_auth_token");
   // config.headers["x-api-key"] = process.env.REACT_APP_X_ADMIN_API_KEY
   
   return config;
}, function (error) {
   return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
   return response;
}, function (error) {
   // const status = error.response.status;
   // toast.error(error.response.data.message);
   console.log(error)
   // if(status === 401){
   //    setTimeout(() => {
   //       window.location.href = '/sign-in';
   //    }, 5000);
   // }
   
   return Promise.reject(error);
});