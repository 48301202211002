import "./header.scss";
import ProfileImage from '@assets/images/profile-img.png';

const ProfileImg = ({ image }) => {
  return(
    <div className='profile-img-wrapper'>
      <img src={ProfileImage} alt='' />
    </div>
  )
}
export default ProfileImg;