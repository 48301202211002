import './bottomNavbar.scss';
import { Link } from 'react-router-dom';
import {ReactComponent as ProposalsIcon} from '@assets/icons/BottomNavbar/proposals.svg';
import {ReactComponent as ProfileIcon} from '@assets/icons/BottomNavbar/profiles.svg';
import {ReactComponent as NotificationIcon} from '@assets/icons/BottomNavbar/notification-icon.svg';
import {ReactComponent as Home} from '@assets/icons/BottomNavbar/home-icon.svg';

const pages = [
  {
    Icon: Home,
    label: 'Home',
    path: '/',
  },
  {
    Icon: ProfileIcon,
    label: 'Profiles',
    path: '/profiles',
  },
  {
    Icon: NotificationIcon,
    label: 'Notification',
    path: '/',
  },
  {
    Icon: ProposalsIcon,
    label: 'Campaigns',
    path: '/campaign-list',
  },
]


const BottomNavbar = ({ active, showLabel }) => {

  return(
    <>
      <div className='bottom-navbar'>
        {
          pages.map(({Icon, label, path}, index) => (
            <Link 
              to={path}
              key={index}
              className={`bottom-navbar-link ${label === active ? 'active' : ''}`}
            >
              <Icon />
              {showLabel ? label : ''}

              <div className={`active-dot ${showLabel ? 'margin-top-9' : 'margin-top-4'} `}></div>
            </Link>
          ))
        }
      </div>
    </>
  )
}
export default BottomNavbar;