import "./campaigns.scss";
import { useState, useEffect } from "react";
import Header from "@components/common/Header";
import { useNavigate } from 'react-router-dom';
import StatusTabs from '@components/CampaignList/StatusTabs';
import CampaignsCard from "@components/CampaignList/CampaignsCard";
import CampaignImg from "@assets/icons/Campaigns/campaign.png";
import CampaignService from "@services/campaign";
import { toast } from 'react-toastify';
// import { Link } from "react-router-dom";
// import { ReactComponent as BackArrow } from "@assets/icons/Common/back-arrow.svg";
// import ProfileImage from '@components/common/Header/profileImg';


const CampaignsArray = [
  {
    title: "Nike Crypto Kicks IRL",
    from: "10th April 2023",
    to: "18th April 2023",
    budget: "8,800",
    status: "Active",
    image: CampaignImg,
    socialData: {
      youtube: { video : 8 },
      facebook: { posts: 0, video: 0, stories: 0 },
      instagram: { posts: 2, video: 1, stories: 0 },
      twitter: { tweets: 1, posts: 2 }
    }
  },
  {
    title: "Nike Crypto Kicks IRL",
    from: "25th April 2023",
    to: "30th April 2023",
    budget: "8,800",
    status: "Upcoming",
    image: CampaignImg,
    socialData: {
      youtube: { video : 8 },
      facebook: { posts: 0, video: 0, stories: 0 },
      instagram: { posts: 2, video: 1, stories: 0 },
      twitter: { tweets: 1, posts: 2 }
    }
  },
  {
    title: "Nike Crypto Kicks IRL",
    from: "10th April 2023",
    to: "20th April 2023",
    budget: "8,800",
    status: "Terminated",
    image: CampaignImg,
    socialData: {
      youtube: { video : 8 },
      facebook: { posts: 0, video: 0, stories: 0 },
      instagram: { posts: 2, video: 1, stories: 0 },
      twitter: { tweets: 1, posts: 2 }
    }
  },
  {
    title: "Nike Crypto Kicks IRL",
    from: "6th April 2023",
    to: "10th April 2023",
    budget: "8,800",
    status: "Completed",
    image: CampaignImg,
    socialData: {
      youtube: { video : 8 },
      facebook: { posts: 0, video: 0, stories: 0 },
      instagram: { posts: 2, video: 1, stories: 0 },
      twitter: { tweets: 1, posts: 2 }
    }
  },
  {
    title: "Nike Crypto Kicks IRL",
    from: "",
    to: "",
    budget: "",
    status: "Pending",
    pendingReason: 'meeting',
    image: CampaignImg,
    socialData: {
      youtube: { video : 8 },
      facebook: { posts: 0, video: 0, stories: 0 },
      instagram: { posts: 2, video: 1, stories: 0 },
      twitter: { tweets: 1, posts: 2 }
    }
  },
  {
    title: "Nike Crypto Kicks IRL",
    from: "",
    to: "",
    budget: "",
    status: "Pending",
    pendingReason: null,
    image: '',
    socialData: {
      youtube: { video : 8 },
      facebook: { posts: 0, video: 0, stories: 0 },
      instagram: { posts: 2, video: 1, stories: 0 },
      twitter: { tweets: 1, posts: 2 }
    }
  },
]


const CampaignsList = () => {
  
  const navigate = useNavigate();
  const [ activeTab, setActiveTab ] = useState('Active');
  const [ campaignsData, setCampaignsData ] = useState([]);
  const [ campaigns, setCampaignsList ] = useState([]);
  const [ loader, setLoader ] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);

    const list = campaigns.filter(campaign => campaign.status.toLowerCase() === activeTab.toLowerCase());
    setCampaignsData(list);
  }, [activeTab, campaigns])
  
  useEffect(() => {
    getcampaignList()
  }, [])

  const getcampaignList = async () => {
    setLoader(true)
    try{
      const res = await CampaignService.campaignList();
      const data = res.data;
      if(data.status){
        setCampaignsList(data.response.campaigns)
      }
      else{
        toast.info(data.message);
      }
    }
    catch(error){
      toast.warning(error)
    }
    setLoader(false)
  }

  return(
    <div className='campaigns-page'>
      <div className='bg'></div>
      <Header 
        onHandleClick={() => navigate('/')}
        title='Campaign'
        // transparentBG={true}
      />

      <div className='br'></div>

      <StatusTabs 
        activeTab={activeTab}
        setTab={setActiveTab}
      />

      {
        loader ?
          <div className='not-found-text'>...</div>
        :
          campaignsData.length > 0 ? campaignsData.map((campaign, index) => (
            <CampaignsCard
              key={index}
              data={campaign}
            />
          ))
          :
          <div className='not-found-text'> {activeTab} campaigns not found.</div>
      
      }
      
    </div>
  )
}
export default CampaignsList;

