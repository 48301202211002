import { useState, useEffect } from 'react';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import BottomNavbar from '@components/common/BottomNavbar';
import CampaignsIcon from '@assets/icons/dashboard/campaign-list.svg';
import ProposalsIcon from '@assets/icons/dashboard/proposals-icon.svg';
import DashboardCards from './dashboardCards';
import PaymentIcon from '@assets/icons/dashboard/payment-and-billing.svg';
import TargetIcon from "@assets/icons/dashboard/target.svg";
import InsightsIcon from '@assets/icons/dashboard/insights.svg';
import TotalEarned from '@assets/icons/dashboard/total-earned.svg';
import CalenderIcon from '@assets/icons/dashboard/calendar-clock.svg'
import Rocket from '@assets/icons/dashboard/rocket.svg';
import { ReactComponent as AddIcon } from '@assets/icons/dashboard/add-icon.svg';
import AddProjectModal from './addProjectModal';
import AddCampaignModal from "./addCampaignModal";
import { removeItem, getItem } from "@utils/getAndSetDataToStorage";


const ManageLinks = [
  {
    icon : CampaignsIcon,
    label : 'Manage Campaigns',
    path : '/campaign-list'
  },
  {
    icon : ProposalsIcon,
    label : 'Manage Onboarding',
    path : '/manage-onboarding'
  },
  {
    icon : PaymentIcon,
    label : 'Payments & Billing',
    path : '#'
  },
  {
    icon : CalenderIcon,
    label : 'Meeting Calendar',
    path : '/meeting-calender'
  },
  {
    icon : InsightsIcon,
    label : 'Insights',
    path : '#'
  }
]

const DashboardCardsData = [
  {
    icon : Rocket,
    label : 'Influencers Onboarded',
    value : '48',
    appreciation : '30%'
  },
  {
    icon : Rocket,
    label : 'Projects Onboarded',
    value : '48',
    appreciation : '33%'
  },
  {
    icon : TargetIcon,
    label : 'Total Campaigns',
    value : '48',
    appreciation : '30%'
  },
  {
    icon : TotalEarned,
    label : 'Total Earned',
    value : '$48,900',
    appreciation : '33%'
  },
]

const addLinks = [
  {
    label : 'Add Campaign',
    path : '#',
  },
  {
    label : 'Add Project',
    path : '/questions-for-project',
  },
  {
    label : 'Add Influencer',
    path : '/question-for-influencer',
  },
]

const Home = () => {

  const navigate = useNavigate();
  const [showAddWindow, setShowAddWindow] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [onboardedProject, setOnboardedProjecs] = useState([]);

  const openNewPage = (link, label) => {
    if(label === 'Add Project'){
      setShowProjectModal(true);
    }
    else if(label === 'Add Campaign'){
      setShowCampaignModal(true);
    }
    else{
      navigate(link)
    }
  }

  useEffect(() => {
    removeItem("project_questions");
    removeItem("campaign_details");
    removeItem("project_id");
    removeItem("project_onboarding_details");
  }, [])
  
  return(
    <>
      <div className='main-container'>
        <Header />
        
        <div className='home'>
          <AddProjectModal 
            showModal={showProjectModal}
            setModal={setShowProjectModal}
          />

          <AddCampaignModal 
            showModal={showCampaignModal}
            setModal={setShowCampaignModal}
          />

          <div>
            <h3 className='dashboard-titles'>
              Your Dashboard
            </h3>
          
            <DashboardCards 
              data={DashboardCardsData}
            />

            <div className='dashboard-titles'>
              Manage
            </div>

            <div className='manage-link-container'>
              {
                ManageLinks.map((item, key) =>
                  <Link to={item.path} className='link-container' key={`link-${key}`}>
                    <div 
                      className='icon-container'
                    >
                      <img src={item.icon} alt='' />
                    </div>

                    <div 
                      className='manage-link'
                    >
                      {item.label}
                    </div>

                  </Link>
                )
              }
            </div>
          </div>
          
          <div>
            <div className={`add-wrapper ${showAddWindow ? 'show-add-wrapper' : ''}`}>
              {
                addLinks.map((item, ind) => (
                  <button
                    className='add-wrapper-button'
                    onClick={() => openNewPage(item.path, item.label)}
                    key={ind}
                  >
                    <AddIcon className='add-icon' />
                    {item.label}
                  </button>
                ))
              }
            </div>
            <button 
              className={`add-button ${showAddWindow ? 'add-button-active' : ''}`}
              onClick={() => setShowAddWindow(!showAddWindow)}
            >
              <AddIcon />
              Add
            </button>
            <BottomNavbar 
              active='Home'
              showLabel={true}
            />
          </div>
        </div>

      </div>
    </>
  )
}
export default Home;