import "./reviewProposal.scss";
import { Link } from 'react-router-dom';
import Header from '@components/common/Header';
import Title from '@components/common/TitleContainer';
import InfluencerCard from '@components/RecommendedInfluencers/influncerCard.jsx';
import QuestionWithAnswer from "@components/common/QuestionWithAnswer";

const selectedInfluencers = [
  {
    profileImage: 'https://picsum.photos/200',
    name: 'John Doe',
    influncerType : "twitter",
    rating: 4.5,
    combinedReach: "408k",
    engagement: "24%",
    project: "48",
  },
  {
    profileImage: 'https://picsum.photos/200',
    name: 'John Doe',
    influncerType : "twitter",
    rating: 4.5,
    combinedReach: "408k",
    engagement: "24%",
    project: "48",
  },
]

const QuestionAnswerList = [
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
]

const ReviewProposal = () => {

  return(
    <>
      <div className='main-container'>
        <Header />
        <div className='review-proposal'>
          <Title 
            title='Review Proposal'
          />

          <div className='questions-answer-wrapper'>
            {
              QuestionAnswerList?.map((item, ind) => (
                <QuestionWithAnswer 
                  data={item}
                  key={ind}
                  index={ind}
                />
              ))
            }
          </div>

          {
            selectedInfluencers.map((item, ind) => (
              <div key={ind}>
                <div className='influencer-number'>{ind + 1}. Influencers </div>
                <InfluencerCard 
                  details={item}
                />
              </div>
            ))
          }

          <Link 
            to='/proposal-sent'
            className='common-button proposal-button'
          >
            Send Proposal to both
          </Link>
        </div>
      </div>
    </>
  )
}
export default ReviewProposal;

