import { useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import Header from '@components/common/Header';
import Title from '@components/common/TitleContainer';
// import QuestionWithAnswer from "@components/common/QuestionWithAnswer";
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import EditableQuestionAnswer from '@components/common/EditableQuestionAnswer';

const QuestionAnswerList = [
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
]

const AnsweredQuestions = () => {

  const [questionsList, setQuestionList] = useState(QuestionAnswerList);
  const [editQuestion, setEditQuestion] = useState(false);
  
  const changeQuestion = (value, ind, type) => {
    const list = [...questionsList];
    type === 'question' ? list[ind].question = value : list[ind].answer = value;
    setQuestionList(list);
  }
  
  return(
    <div className='main-container'>
      <Header />
      <div className='received-answer'>
        <Title 
          title='Answers recieved'
        />

        <div className='d-flex align-items-center justify-content-between margin-bottom-18'>
          <div className='title'>Campaign Details</div>

          <button 
            className={`edit-button ${editQuestion ? '' : 'bg-white'}`}
            onClick={() => setEditQuestion(!editQuestion)}
            title={editQuestion ? 'Edit done' : 'Edit'}
          >
            <Edit />
          </button>
        </div>

        <div className='questions-answer-wrapper'>
          {
            questionsList?.map((item, ind) => (
              <EditableQuestionAnswer 
                data={item}
                number={ind}
                changeQuestion={changeQuestion}
                key={ind}
                edit={editQuestion}
              />
            ))
          }
        </div>

        <Link
          to='/'
          className='common-button'
        >
          Next
        </Link>
      </div>
    </div>
  )
}
export default AnsweredQuestions;