import { useState, useEffect } from 'react';
import TextInput from "@components/common/InputTypes/TextInput";
import FileInput from "@components/common/InputTypes/FileInput";
import Boolean from "@components/common/InputTypes/Boolean";
import CustomSelect from "@components/common/InputTypes/CustomSelect";
import SocialMediaUrlsInput from "@components/common/InputTypes/SocialMediaUrlsInput";
import Textarea from "@components/common/InputTypes/Textarea";
import MulipleSelectTabs from "@components/common/InputTypes/MultipleSelectTabs";
import DateRangePicker from "@components/common/InputTypes/DateRangePicker";
import TweetContent from "@components/common/InputTypes/TweetContent";
import ProjectService from "@services/project";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {CampaignContent} from "@config/Questions";

const thread = {
   content: '',
   document: ''
}

const QuestionsContainer = ({ questions, setQuestions, numberOfTweets, campaignDuration }) => {

   const [showDateRangeModal, setShowDateRangeModal] = useState(false);
   const [campaingStartDate, setCampaignStartDate] = useState('');
   const [campaingEndDate, setCampaignEndDate] = useState('');

   useEffect(() => {
      if(campaignDuration){
         setCampaignStartDate(campaignDuration.answer[0]);
         setCampaignEndDate(campaignDuration.answer[1]);
      }
      else{
         const duration = questions.find((obj) => obj.questionId === "campaign duration")?.answer;
         setCampaignStartDate(duration?.[0]);
         setCampaignEndDate(duration?.[1])
      }
   }, [campaignDuration, questions, campaingStartDate, campaingEndDate])

   const handleSocialMediaUrls = (value, number, ind) => {
      const list = [...questions];
      const urls = list[number].socialMediaUrls;
      urls[ind].url = value;
      setQuestions(list);
      
      const isFilled = urls.every((obj) => obj.url !== '');
      if(isFilled) list[number].answer = 'Social URLs received';
      else list[number].answer = '';
   }

   const handleChangeTabSelection = (value, number,ind) => {
      const list = [...questions];
      const options = list[number].options;
      options[ind].selected = !options[ind].selected;
      const checkOptions = options.every((obj) => obj.selected === false);
      if(!checkOptions) list[number].answer = 'Answer Received'
      else list[number].answer = ''
      setQuestions(list);
   }

   const handleDateRangeChange = (value, number) => {
      const list = [...questions];
      const obj = list[number];
      obj.answer = [...value];
      setQuestions(list); 
      setCampaignStartDate(value[0]);
      setCampaignEndDate(value[1]); 
   }

   const handleDeleteUrl = (number, ind) => {
      const list = [...questions];
      const urlArray = list[number];
      urlArray.socialMediaUrls.splice(ind, 1)
      setQuestions(list);
   }

   const changeQuestion = (value, ind) => {
      const list = [...questions];
      list[ind].questionLabel = value;
      setQuestions(list);
   }

   const deleteQuestion = (ind) => {
      const list = [...questions];
      list.splice(ind, 1);
      setQuestions(list);
   }

   const editQuestionByInd = (ind) => {
      const list = [...questions];
      list[ind].editQuestion = true;
      setQuestions(list);
   }

   const changeAnswer = (value, ind) => {
      const list = [...questions];
      list[ind].answer = value;
      setQuestions(list);
   }

   const handleChangeFile = async (file, num) => {
      const fileName = await uploadFile(file);
      if(fileName){
         const list = [...questions];
         list[num].answer = fileName;
         setQuestions(list);
      }
   }

   const uploadFile = async (file) => {
      if(file){
         try {
            const formData = new FormData();
            formData.append('document', file);
   
            const response = await ProjectService.uploadFile(formData);
            if (response.status === 200) {
               const data = response.data.data;
               return data;
            }
         } catch (error) {
            toast.error(error.message);
         }
      }
   };

   const onChangeFile = async (value, ind, number) => {
      const fileName = await uploadFile(value);
      if (fileName) {
         setQuestions((prevQuestions) => {
            const newList = [...prevQuestions];
            const contentArray = newList[number].content;            
            const newContentArray = [...contentArray];
            newContentArray[ind] = {
            ...newContentArray[ind],
            document: fileName,
            };
            newList[number] = {
            ...newList[number],
            content: newContentArray,
            };
            return newList;
         });
      }
   };

   const onThreadFileChange = async (file, tweetIndex, threadInd, number) => {
      const fileName = await uploadFile(file);
      
      if (fileName) {
         setQuestions((prevQuestions) => {
            const newList = [...prevQuestions];
            const contentArray = newList[number].content;
            const newContentArray = [...contentArray];
            const threadsArray = newContentArray[tweetIndex].threads;
            const newThreadsArray = [...threadsArray];
            newThreadsArray[threadInd] = {
            ...newThreadsArray[threadInd],
            document: fileName,
            };
            newContentArray[tweetIndex] = {
            ...newContentArray[tweetIndex],
            threads: newThreadsArray,
            };
            newList[number] = {
            ...newList[number],
            content: newContentArray,
            };
            return newList;
         });
      }
   };

   const handleOnContentChange = (value, ind, number) => {
      setQuestions((prevQuestions) => {
         const newList = [...prevQuestions];
         const contentArray = newList[number].content;
         const newContentArray = [...contentArray];
         newContentArray[ind] = {
            ...newContentArray[ind],
            content: value,
         };
         newList[number] = {
            ...newList[number],
            content: newContentArray,
         };
         return newList;
      });
   }

   const handleAddThread = (ind, number) => {
      setQuestions((prevQuestions) => {
         const newList = [...prevQuestions];
         const contentArray = newList[number].content;
         const newContentArray = [...contentArray];
         const threadsArray = newContentArray[ind].threads;
         const newThreadsArray = [...threadsArray];
         newThreadsArray.push(thread);
         newContentArray[ind] = {
            ...newContentArray[ind],
            threads: newThreadsArray,
         };
         newList[number] = {
            ...newList[number],
            content: newContentArray,
         };
         return newList;
      });
   }

   const removeThread = (threadInd, ind, number) => {
      setQuestions((prevQuestions) => {
         const newList = [...prevQuestions];
         const contentArray = newList[number].content;
         const newContentArray = [...contentArray];
         const threadsArray = newContentArray[ind].threads;
         const newThreadsArray = [...threadsArray];
         newThreadsArray.splice(threadInd, 1);
         newContentArray[ind] = {
            ...newContentArray[ind],
            threads: newThreadsArray,
         };
         newList[number] = {
            ...newList[number],
            content: newContentArray,
         };
         return newList;
      })
   }

   const handleThreadContentChange = (value, tweetIndex, threadIndex, number) => {
      setQuestions((prevQuestions) => {
         const newList = [...prevQuestions];
         const contentArray = newList[number].content;
         const newContentArray = [...contentArray];
         const threadsArray = newContentArray[tweetIndex].threads;
         const newThreadsArray = [...threadsArray];
         newThreadsArray[threadIndex] = {
            ...newThreadsArray[threadIndex],
            content: value,
         };
         newContentArray[tweetIndex] = {
            ...newContentArray[tweetIndex],
            threads: newThreadsArray,
         };
         newList[number] = {
            ...newList[number],
            content: newContentArray,
         };
         return newList;
      });
   };

   const handleChangeDateAndTime = (value, ind, number) => {
      const formattedValue = dayjs(value).format('ddd MMM D YYYY HH:mm:ss [GMT]ZZ (Z)');
      const splitString = formattedValue.split(" ");
      const date = `${splitString[1]} ${splitString[2]} ${splitString[3]}`;
      const time = `${splitString[4]} ${splitString[5]} ${splitString[6]}`;
      
      setQuestions((prevQuestions) => {
         const newList = [...prevQuestions];
         const contentArray = newList[number].content;
         const newContentArray = [...contentArray];
         const tweet = newContentArray[ind];
         tweet.postDateAndTime = formattedValue;
         tweet.postDate = date;
         tweet.postTime = time;
         newList[number] = {
            ...newList[number],
            content: newContentArray,
         };
         return newList;
      });
   }

   const onChangeTweets = (value, number) => {
      const list = [...questions];
      let tweetContents = list[number].content;
      const tLength = tweetContents.length;
      list[number].answer = value;

      if (tLength > value) {
        const diff = tLength - value;
        tweetContents.splice(-diff);
      }
      else{
         const diff = value - tLength;
         for (let index = 0; index < diff; index++) {
            tweetContents.push(CampaignContent)
         }
      }
      setQuestions(list);
   }

   return(
      <>
         {
            questions?.map((item, ind) => (
               <>
               {
                  (item.type === 'text' || item.type === 'number') &&
                  <TextInput
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeAnswer={changeAnswer}
                     editQuestion={item.disabledEdit}
                     deleteQuestion={deleteQuestion}
                     setQuestion={editQuestionByInd}
                  />
               }
               {
                  item.type === 'textarea' &&
                  <Textarea
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeAnswer={changeAnswer}
                     editQuestion={item.disabledEdit}
                     deleteQuestion={deleteQuestion}
                     setQuestion={editQuestionByInd}
                  />
               }
               {
                  item.type === 'tabs' &&
                  <MulipleSelectTabs
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeAnswer={handleChangeTabSelection}
                     editQuestion={item.disabledEdit}
                     deleteQuestion={deleteQuestion}
                     setQuestion={editQuestionByInd}
                  />
               }
               {
                  item.type === 'boolean' &&
                  <Boolean 
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeAnswer={changeAnswer}
                     editQuestion={item.disabledEdit}
                     deleteQuestion={deleteQuestion}
                     setQuestion={editQuestionByInd}
                  />
               }
               {
                  item.type === 'file' &&
                  <FileInput 
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeAnswer={handleChangeFile}
                     editQuestion={item.disabledEdit}
                     deleteQuestion={deleteQuestion}
                     setQuestion={editQuestionByInd}
                  />
               }
               
               {
                  item.type === 'select' &&
                  <CustomSelect 
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeAnswer={changeAnswer}
                     setQuestion={editQuestionByInd}
                     deleteQuestion={deleteQuestion}
                     editQuestion={item.disabledEdit}
                  />
               }

               {
                  item.type === 'social-media-urls' &&
                  <>
                     <SocialMediaUrlsInput
                        number={ind}
                        data={item}
                        ChangeUrl={handleSocialMediaUrls}
                        deleteUrl={handleDeleteUrl}
                     />
                  </>
               }

               {
                  item.type === 'tweet content' && campaingStartDate !== '' &&
                  <TweetContent 
                     number={ind}
                     data={item}
                     onChangeQuestion={changeQuestion}
                     onChangeTweets={onChangeTweets}
                     setQuestion={editQuestionByInd}
                     deleteQuestion={deleteQuestion}
                     editQuestion={item.disabledEdit}
                     startDate={campaingStartDate}
                     endDate={campaingEndDate}
                     onChangeFile={onChangeFile}
                     onThreadFileChange={onThreadFileChange}
                     handleOnContentChange={handleOnContentChange}
                     handleAddThread={handleAddThread}
                     handleThreadContentChange={handleThreadContentChange}
                     handleChangeDateAndTime={handleChangeDateAndTime}
                     removeThread={removeThread}
                  />
               }

               {
                  item.type === 'date-range' && 
                  <>
                     <DateRangePicker
                        number={ind}
                        data={item}
                        onChangeQuestion={changeQuestion}
                        onChangeAnswer={handleDateRangeChange}                        
                        editQuestion={item.disabledEdit}
                        showModal={showDateRangeModal}
                        setModal={setShowDateRangeModal}
                     />
                  </>
               }
               </>
            ))
         }
      </>
   )
}
export default QuestionsContainer;