import "./projectProfile.scss";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "@assets/images/ProjectProfile/banner.png";
import logo from "@assets/images/ProjectProfile/project-logo.png";
import BackArrow from "@assets/images/ProjectProfile/left-arrow.svg";
// import { ReactComponent as FollowIcon } from "@assets/icons/ProjectProfile/follow.svg";
import ProjectSocialDetails from "@components/ProjectProfile/ProjectSocialDetails";
import ProjectDetails from "@components/ProjectProfile/ProjectDetails";
import BottomNavbar from '@components/common/BottomNavbar';
import ProjectService from "@services/project";
import campaignServices from "@services/campaign";
import { getItem } from "@utils/getAndSetDataToStorage";
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as MeetingRequest } from "@assets/icons/ProjectProfile/meeting-request.svg";
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import moment from "moment";
import { CampaignQuestions2 } from "@config/Questions";

const ProjectProfile = () => {

  const navigate = useNavigate();
  const [projectdetails, setProjectDetails] = useState("");
  const [campaignQuestions, setCampaignQuestions] = useState("")
  const [edit, setEdit] = useState(true);
  const unique_id = uuid();

  useEffect(() => {
    fetchProfileDetails();
  }, [])

  const fetchProfileDetails = async () => {
    const id = getItem("project_id");
    await ProjectService.getProjectProfile(id).then((res) => {
      if(res.status === 200){
        const data = res.data.data;
        setProjectDetails(data);
      }
    }).catch((error) => {
      toast.error(error);
      console.log(error);
    });    
  }

  const handleEditMode = async () => {
    if(edit){
      toast.info("You are now in edit mode.")
      setEdit(false)
    }
    else{
      const projectId = getItem("project_id");
      const adminDetails = getItem("user_profile", true)
      if(adminDetails?.uid){
        const data = {
          projectId,
          projectdetails,
          adminId: adminDetails.uid
        }
        await ProjectService.editProjectProfile(data).then((res) => {
          const data = res.data;
          if(data.status){
            // setProjectDetails(data.profile)
            setEdit(true)
            toast.success(data.message)
            toast.info("Edit mode off.")
          }
          else{
            const error = data.message
            toast.error(error);
          }
        })
      }
      else{
        toast.error("User is not defined please login again.")
        setTimeout(() => {
          navigate("/sign-in")
        }, 5000);
      }
    }
  }

  const handleChangeDetails = (value, feildName) => {
    const details = {...projectdetails};
    details[feildName] = value;
    setProjectDetails(details);
  }

  const addCampaign = async () => {
    try{
      let now = moment();
      const uniqueId = unique_id;
      const shortId = uniqueId.length > 6 ? uniqueId.substring(0, 6) : uniqueId;
      const questions = [...CampaignQuestions2];
      setCampaignQuestions(questions);
      const camp_name = `${projectdetails.name}-${shortId}`
      campaignQuestions[0].answer = camp_name;
      const project_id = getItem("project_id")
      const deepLink = `http://localhost:3001/campaign-questions?id=${uniqueId}`

      const details = {
        campaignName: camp_name,
        campaignId: uniqueId,
        projectId: project_id,
        projectName: projectdetails.name,
        questionsDeepLink: deepLink,
        deepLinkSentOn: now.format(),
        campaignQuestions: JSON.stringify(campaignQuestions),
        withCampaign: false,
        email: projectdetails.email
      }

      await campaignServices.createCampaign(details).then((res) => {
        const data = res.data;
        if(data.status === true){
          navigate('/meeting-request');
        }
      }).catch((err) => {
        toast.warning(err);
      })
    }
    catch(err){
      toast.error(err);
    }
  }

  return(
    <div className='project-profile-page'>
      <div className='profile-banner-wrapper'>
        <img src={Banner} alt='' />

        <Link 
          to="/manage-onboarding"
          className='back-button'
        >
          <img src={BackArrow} alt='' />
        </Link>
        <div className='project-logo-wrapper'>
          <img src={logo} alt='' />
        </div>
      </div>
      <div className='profile-page-px'>
        <div className='d-flex justify-content-end'>
          <button 
            className={`edit-button position-relative ${!edit? 'active' : ''}`}
            onClick={handleEditMode}
          >
            <Edit />
          </button>
        </div>
        
        {projectdetails &&
          <ProjectSocialDetails 
            project={projectdetails}
            editable={edit}
            handleChange={handleChangeDetails}
          />
        }
      </div>

      <ProjectDetails 
        project={projectdetails}
        editable={edit}
        handleChange={handleChangeDetails}
      />


      <div className='profile-page-px'>
        <button 
          className='common-button contant-button'
          onClick={addCampaign}
        >
          <MeetingRequest />
          Send a Meeting Request
        </button>
        
        <BottomNavbar 
          active='Home'
        />
      </div>
    </div>
  )
}
export default ProjectProfile;