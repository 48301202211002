import { Link } from "react-router-dom";
import Header from '@components/common/Header';
import CompanyDetails from '@components/CampaignInfo/CompanyDetails';
import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import QuestionWithAnswer from "@components/common/QuestionWithAnswer";
import { useNavigate } from 'react-router-dom';

const QuestionAnswers = [
  {
    question: "What's the name of your Campaign ?",
    answer: 'UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments.'
  },
  {
    question: "What's the name of your Campaign ?",
    answer: 'UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments.'
  },
  {
    question: "What's the name of your Campaign ?",
    answer: 'UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments.'
  },
]

const CampaignInfo = () => {

  const navigate = useNavigate();
  
  return(
    <div className='main-container'>
      <Header 
        onHandleClick={() => navigate("/")}
      />
      <div className='campaign-details-page'>
        <div>
          <CompanyDetails 
            name='Unore'
            logo={DummyProfileImg}
            onBoardDate='24th Oct 2026'
          />

          <div className='d-flex align-items-center justify-content-between margin-bottom-20'>
            <div className='title'>
              Campaign Details
            </div>
            <button className='edit-button'>
              <Edit />
            </button>
          </div>

          {
            QuestionAnswers?.map((item, ind) => (
              <QuestionWithAnswer 
                data={item}
                key={ind}
                index={ind}
              />
            ))
          }
        </div>

        <Link 
          to="/recommended-influencers"
          className='common-button margin-top-30'
        >
          Confirm and Proceed
        </Link>
      </div>
    </div>
  )
}
export default CampaignInfo;