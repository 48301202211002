import "./index.scss"; 
import { Link } from "react-router-dom";
import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
// import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
import { ReactComponent as Arrow } from "@assets/icons/CampaignDetail/link-arrow.svg";

const InfluencerCard = ({ data, showCalendarLink }) => {

  return(
    <div className='influencer-detail-card'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <div className='profile-image-wrapper'>
            <img src={data.image} alt='' />
          </div>
          <div>
            <div className='name'> {data.name} </div>
            <div className='influencer-type d-flex align-items-center'>
              <Twitter className='social-icon' />
              Twitter Influencer
            </div>
          </div>
        </div>
        <div className='budget-amount'>
          $4200
        </div>
      </div>

      <div className='br' />

      <div className='d-flex'>
        <div className='social-tab'>
          <Twitter />
          <div>8 Tweets, 4 Posts</div>
        </div>
        <div className='social-tab'>
          <Youtube />
          8 Videos
        </div>
        <div className='social-tab'>
          <Instagram />
          2 Stories
        </div>
      </div>
      {
        showCalendarLink &&
        <Link 
          to='/'
          className='cta-button d-flex align-items-center justify-content-center'
        >
          View Content Calendar
          <Arrow />
        </Link>
      }
    </div>
  )
}
export default InfluencerCard;