import "./campaignCard.scss";
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
import { ReactComponent as Arrow } from "@assets/icons/Campaigns/view-link-arrow.svg";
import { setItem } from "@utils/getAndSetDataToStorage";
import StatusPill from "@components/common/Status";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
// import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
// import { ReactComponent as CompeletedIcon } from "@assets/icons/Campaigns/completed.svg";
// import { ReactComponent as UpcomingIcon } from "@assets/icons/Campaigns/upcoming.svg";
// import { ReactComponent as TerminatedIcon } from "@assets/icons/Campaigns/terminated.svg";


const CampaignsCard = ({ data }) => {

  const navigate = useNavigate();
  const { campaignName, title, from, to, budget, status, image, socialData } = data;

  console.log(data)

  const setCampaignDetails = () => {
    setItem("campaign_details", JSON.stringify(data))
    navigate("/update-campaign")
  }

  return(
    <>
      {
        status.toLowerCase() === 'pending' ?
          <div className='campaigns-card pending-campaign-card' onClick={setCampaignDetails}>
      
            <div className='details'>
              <StatusPill 
                status="pending"
              />

              <div className={`title margin-top-12 ${from ? '' : 'margin-bottom-19'}`}>
                {campaignName}
              </div>
              {(from && to) &&
                <div className='d-flex align-items-center campaign-date'>
                  <span> {from} </span>
                  <span> &nbsp; - &nbsp;</span>
                  <span> {to} </span>
                </div>
              }
      
              <div className='d-flex align-items-center justify-content-between'>
                {budget ?
                  <div className='budget-amount'>
                    ${budget}
                  </div>
                  :
                  <div className='empty-line'>---</div>
                }
                
                <div
                  onClick={setCampaignDetails}
                  className='d-flex align-items-center view-details'
                >
                  <span>View Details</span>
                  <Arrow />
                </div>
              </div>
            </div>
          </div>
        :
          <div className='campaigns-card'>
            <div className='banner-wrapper'>
              <img src={image} alt={title} />
            </div>
      
            <div className='d-flex justify-content-end details'>
              <div className='social-tab'>
                <Twitter />
                <div>8 Tweets, 4 Posts</div>
              </div>
              <div className='social-tab'>
                <Youtube />
                8 Videos
              </div>
              <div className='social-tab'>
                <Instagram />
                2 Stories
              </div>
            </div>
      
            <div className='details'>
              <div className='title'>
                {title}
              </div>
              <div className='d-flex align-items-center campaign-date'>
                <span> {from} </span>
                <span> &nbsp; - &nbsp;</span>
                <span> {to} </span>
              </div>
      
              <div className='d-flex align-items-center justify-content-between'>
                <div className='budget-amount'>
                  ${budget}
                </div>
                
                <div
                  onClick={setCampaignDetails}
                  className='d-flex align-items-center view-details'
                >
                  <span>View Details</span>
                  <Arrow />
                </div>
              </div>
            </div>
          </div>
          
      }
    </>
  )
}
export default CampaignsCard;