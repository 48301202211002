import axios from "axios";
import config from "../config";

const users = {};

const baseUrl = config.baseUrl;

users.createUser = async (data) => {
   const url = `${baseUrl}/create-user`;
   const res = await axios({
      url,
      method : "POST",
      data
   })

   return res;
}

users.getUser = async (uid, role) => {
   const url = `${baseUrl}/get-user/${uid}/${role}`;
   const res = await axios({
      url,
      method : "GET",
   })

   return res;
}

export default users;