import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';

const Textarea = (props) => {

   const { 
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      setQuestion, 
      deleteQuestion,
      editQuestion
   } = props;

   return(
      <>
         <div className='question-container'>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
                  disabled={editQuestion}
               />
            </div>

            <div className='d-flex answer-container align-items-center'>
               <textarea 
                  type='text'
                  className='answer-input text-area margin-right-5'
                  onChange={(e) => onChangeAnswer(e.target.value, number)}
                  value={data.answer}
                  placeholder='Enter your answer'
               />

               <button 
                  className='delete-button'
                  onClick={() => deleteQuestion(number)}
               >
                  <Delete />
               </button>
            </div>
         </div>
      </>
   )
}
export default Textarea;