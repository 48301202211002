import "./index.scss";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
import Close from "@assets/icons/Common/close-icon.png";

const followingCount = [
   {
      followers: '508k',
      platform: 'Twitter'
   },
   {
      followers: '508k',
      platform: 'Instagram'
   },
   {
      followers: '508k',
      platform: 'Youtube'
   },
   {
      followers: '508k',
      platform: 'Linkdin'
   },
]


const Language = ['English', 'Hindi', 'Urdu'];

const getLogo = (platform) => {
   switch (platform) {
      case 'Twitter':
         return <Twitter />
      case 'Youtube':
         return <Youtube />
      case 'Linkdin':
         return <LinkedIn />
      case 'Instagram':
         return <Instagram />
      default:
         break;
   }
}

const InfluencerSocialModal = ({ showModal, setModal }) => {

   return(
      <div className={` influencer-social-modal ${showModal ? 'active' : ''}`}>
         <div className='card'>
            <button
               type='button'
               className='close-modal-button'
               onClick={() => setModal(false)}
            >
               <img src={Close} alt='' />
            </button>

            <div className='title'> Reach by Social Platforms </div>

            <div className='social-details-card'>
               {
                  followingCount?.map((item, ind) => (
                     <div className='card' key={ind}>
                        <div className='d-flex align-content-center'>
                           {getLogo(item.platform)}
                           <div className='platform-name'>
                              {item.platform} Followers
                           </div>
                        </div>
                        <div className='follower-number'> {item.followers} </div>
                     </div>
                  ))
               }
            </div>

            <div className='title'>Reach by Geography</div>

            <div className='density-map'></div>

            <div className='title'>Reach by Language</div>

            <div className='d-flex flex-wrap language-wrapper'>
               {
                  Language?.map((item, ind) => (
                     <div className='language' key={ind}>
                        {item}
                     </div>
                  ))
               }
            </div>
         </div>
      </div>
   )
}
export default InfluencerSocialModal;