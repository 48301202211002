import { useState } from 'react';
import "./index.scss";
import { ReactComponent as BadgeCheck } from "@assets/icons/Common/badge-check.svg";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
import { ReactComponent as LinkIcon } from "@assets/icons/Common/media-link.svg";
import DummyImg from "@assets/icons/Common/dummy-attachment.svg";
import PasteLinkModal from "@components/common/PasteLinkModal";

const MarketingContent = ({ data, platform, completedMark, setMark }) => {

  const [postLink, setPostLink] = useState('');
  const [showLinkModal, setShowLinkModal] = useState(false)

  
  const handleLinkChange = (link) => {
    setPostLink(link);
  }

  return(
    <>
      <PasteLinkModal 
        title="Paste Tweet Link"
        show={showLinkModal}
        setModal={setShowLinkModal}
        handleChange={handleLinkChange}
        link={postLink}
        
      />
      <div className='marketing-content-wrapper'>
        <div className='plateform-color-line'></div>

        <div className='d-flex align-items-center justify-content-between'>
          <div className='platform-name'> <Twitter /> 1st Tweet</div>
          <div 
            className={`complete-badge ${completedMark ? 'complete-badge-acitve' : ''}`}
            onClick={() => setShowLinkModal(true)}
          >
            <BadgeCheck />
            {completedMark ? 'Completed' : 'Mark as Complete'}
          </div>
        </div>

        <div className='breaking-line'></div>

        <div className='content-text'>
          Unore’s Governance staking portal is now LIVE Lock your $UNO for veUNO to shape Uno Re’s future & earn up to 60% APRs in the process.
          https://app.unore.io/GOVERNANCEUIP-01 voting goes live soon - don't know where to start?
        </div>

        <div className='breaking-line breaking-line-2'></div>

        <div className='d-flex media-title'>
          <LinkIcon />
          Media
        </div>

        <div className='img-attachment-wrapper'>
          <img src={DummyImg} alt='' />
          <img src={DummyImg} alt='' />
          <img src={DummyImg} alt='' />
          <img src={DummyImg} alt='' />
        </div>
      </div>
    </>
  )
}
export default MarketingContent;