import moment from 'moment-timezone';

const convertDateAndTime = (value, isDateAndTime = false) => {
   const originalTime = moment.utc(value).local();
   let date;
   if (isDateAndTime) {
      date = originalTime.format('ddd MMM DD YYYY HH:mm:ss');
   }
   else{
      date = originalTime.format('ddd MMM DD YYYY');
   }

   return date;
}
export default convertDateAndTime;