// import { useState } from "react";
import "./index.scss";
import { useNavigate } from 'react-router-dom';
import Header from "@components/common/Header";
import { ReactComponent as Success } from '@assets/icons/DeepLink/success.svg';
import { removeItem } from "@utils/getAndSetDataToStorage";

const MeetingRequest = () => {

  const navigate = useNavigate();

  const removeDataFromStorage = () => {
    removeItem("campaign_details");
    removeItem("project_id");
    removeItem("project_onboarding_details");
    navigate("/")
  }

  return(
    <div className="main-container">
      <Header />
      <div className='meeting-request'>
        <div></div>

        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Success />

          <div className='title'>
            Meeting request sent
          </div>

          <div className='desc'>
            A meeting request has  been sent to the company’s official email
          </div>

          <div className='note'>
          Meeting will be added to calendar once the company accept your meeting request
          </div>
        </div>

        <button
          onClick={removeDataFromStorage}
          className='common-button'
        >
          Back Home
        </button>
      </div>
    </div>
  )
}
export default MeetingRequest;