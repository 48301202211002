import { useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { getItem } from "@utils/getAndSetDataToStorage";
import InfluencerSocialDetails from "@components/InfluencerProfile/InfluencerSocialDetails";
import InfluencerDetails from "@components/InfluencerProfile/InfluencerDetails";
import BottomNavbar from '@components/common/BottomNavbar';
import InfluencerService from "@services/influencer";
import { toast } from 'react-toastify';
import Banner from "@assets/images/ProjectProfile/banner.png";
import logo from "@assets/images/ProjectProfile/project-logo.png";
import BackArrow from "@assets/images/ProjectProfile/left-arrow.svg";
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
// import { ReactComponent as FollowIcon } from "@assets/icons/ProjectProfile/follow.svg";
// import { ReactComponent as RightArrow } from "@assets/icons/ProjectProfile/right-arrow.svg";

const InfluencerProfile = () => {

  const [influencerProfile, setInfluencerProfile] = useState({});
  const [disableEdit, setDisableEdit] = useState(true);

  useEffect(() => {
    fetchProfileDetails();
  }, [])

  const fetchProfileDetails = async () => {
    
    const id = getItem("influencer_id");
    await InfluencerService.getInfluencerProfile(id).then((res) => {
      if(res.status === 200){
        const data = res.data.data;
        setInfluencerProfile(data);
      }
    }).catch((error) => {
      console.log(error);
    });    
  }

  const handleEditMode = async () => {
    if(disableEdit){
      toast.info("You are now in edit mode.")
      setDisableEdit(false)
    }
    else{
      const influencerId = getItem("influencer_id");
      const adminDetails = getItem("user_profile", true)

      if(adminDetails?.uid){
        const data = {
          influencerId,
          influencerProfile,
          adminId: adminDetails.uid
        }
        await InfluencerService.editInfluencerProfile(data).then((res) => {
          const data = res.data;
          if(data.status === true){
            setDisableEdit(true)
            setInfluencerProfile(data.profile.profile)
            toast.success(data.message)
            toast.info("Edit mode off.")
          }
          else{
            const error = data.message
            toast.error(error);
          }
        })
      }
      else{
        toast.error("Unauthorized!, Please login again.")
      }
    }
  }

  const handleChangeDetails = (value, feildName) => {
    const details = {...influencerProfile};
    details[feildName] = value;
    setInfluencerProfile(details);
  }

  return(
    <div className='influencer-profile-page'>
      <div className='profile-banner-wrapper'>
        <img src={Banner} alt='' />

        <Link 
          to="/"
          className='back-button'
        >
          <img src={BackArrow} alt='' />
        </Link>
        <div className='project-logo-wrapper'>
          <img src={logo} alt='' />
        </div>
      </div>
      <div className='profile-page-px'>
        <div className='d-flex justify-content-end'>
          <button 
            className={`edit-button position-relative ${!disableEdit? 'active' : ''}`}
            onClick={handleEditMode}
          >
            <Edit />
          </button>
        </div>
        
        <InfluencerSocialDetails 
          influencerDetails={influencerProfile}
          editable={disableEdit}
          handleChange={handleChangeDetails}
        />

        {/* <div className='title'>
          Experience
        </div> */}

      </div>
      
      <InfluencerDetails 
        influencer={influencerProfile}
        editable={disableEdit}
        handleChange={handleChangeDetails}
      />

      <div className='profile-page-px'>
        <BottomNavbar 
          active='Home'
        />
      </div>
    </div>
  )
}
export default InfluencerProfile;