import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import SearchIcon from '@assets/icons/dashboard/search-icon.svg';
import { setItem } from "@utils/getAndSetDataToStorage";
import ProjectService from "@services/project";
import { toast } from 'react-toastify';

const AddCampaignModal = ({ showModal, setModal }) => {

  const navigate = useNavigate();
  const [companiesList, setCompaniesList] = useState([]);
  const [filteredList, setFilteredList] = useState([])

  const getProjectsAndInfluencers = async () => {
    try{
      const status = 'onboarded';
      const res = await ProjectService.getProjectAndInfluencerListByStatus(status, 'projects');
      if(res.status === 200){
        const projectsList = res.data.projectsList;
        setCompaniesList(projectsList)
        setFilteredList(projectsList)
      }
    }
    catch(error){
      toast.warning(error)
    }
  }

  useEffect(() => {
    if(showModal) getProjectsAndInfluencers()
  }, [showModal])

  const filterProjects = (value) => {
    const filtered = companiesList.filter((obj) => obj.name.toLowerCase().includes(value.toLowerCase()));
    if(filtered.length !== 0 && value !== ""){
      setFilteredList(filtered);
    }
    else if(value !== ""){
      setFilteredList([]);
    }
    else{
      setFilteredList(companiesList)
    }
  }

  const handleSetCompany = (id) => {
    setItem('project_id', id)
    navigate("/project-profile")
  }

  return(
    <div 
      className={`custom-modal add-campaign-modal ${showModal ? 'show-modal' : ''}`}
      onClick={() => setModal(false)}
    >
      <div className='modal-card-wrapper' onClick={(e) => e.stopPropagation()}>
        <div className='title'>
          Select Project
        </div>

        <div className='search-bar'>
          <img src={SearchIcon} alt='' />
          <input 
            type='text'
            onChange={(e) => filterProjects(e.target.value)}
            placeholder='Search for onboarded projects'
          />
        </div>

        <div className='compnay-list-wrapper'>
          {filteredList.length > 0 ? filteredList.map((item, index) => (
            <div 
              className='add-campaign-modal-card'
              key={index}
              onClick={() => handleSetCompany(item.id)}
            >
              <img src={item.logo ? item.logo : DummyProfileImg} alt={item.name} className='logo' />

              <div className='name'>
                {item.name}
              </div>
            </div>
          ))
          :
            <div className='not-found-text'>No projects found</div>
          }
        </div>
      </div>
    </div>
  )
}
export default AddCampaignModal;