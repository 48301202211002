export const setItem = (key, value) => {
   return sessionStorage.setItem(key, value);
}
export const getItem = (key, parse=false) => {
   return sessionStorage.getItem(key) ? 
      parse ? JSON.parse(sessionStorage.getItem(key)) : sessionStorage.getItem(key)
      : 
      undefined; 
}
export const removeItem = (key) => {
   return sessionStorage.removeItem(key);
}