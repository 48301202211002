import { useState, useEffect } from 'react';
import './questions.scss';
import { useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import { ReactComponent as AddIcon } from '@assets/icons/QuestionsForProject/add-icon.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/QuestionsForProject/link.svg';
import AddQuestionModal from "@components/common/AddQuestionModal";
import ProjectService from "@services/project";
import { v4 as uuid } from 'uuid';
import moment from "moment";
import { setItem, getItem, removeItem } from "@utils/getAndSetDataToStorage";
import { projectQuestions, CampaignQuestions } from "@config/Questions";
import { toast } from 'react-toastify';
import QuestionsContainer from "@components/common/QuestionsContainer";

const QuestionForProject = () => {

  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState([]);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [withCampaign, setWithCamapign] = useState(false);
  const unique_id = uuid();

  useEffect(() => {
    let project_questions = getItem("project_questions", true);
    if(project_questions !== undefined) setQuestionList([...project_questions])
    else setQuestionList([...projectQuestions])
    
    const isWithCampaign = getItem("add_project_with_campaign", true);
    setWithCamapign(isWithCampaign);
  }, [])

  const submitQuestions = async () => {

    if(checkInputs() === true){
      
      const projectName = questionList.find((obj) => obj.questionId === 'name of project');
      const email = questionList.find((obj) => obj.questionId === "company's email");
      const socialMediaIndex = questionList.findIndex((obj) => obj.type === 'social-media-urls');
      const urls = questionList[socialMediaIndex].socialMediaUrls;
      const isFilled = urls.every((obj) => obj.url !== '');
      if(isFilled) questionList[socialMediaIndex].answer = 'Social URLs received';
      
      const campaignname = projectName + ' campaign';

      let now = moment();
      const uniqueId = unique_id;
      const uniqueId2 = unique_id;
      
      const data = {
        projectName : projectName.answer,
        email: email.answer,
        projectQuestions: JSON.stringify(questionList),
        projectId : uniqueId,
        deepLinkSentOn: now.format(),
        withCampaign,
        campaignName: withCampaign ? campaignname : null,
        campaignId: withCampaign ? uniqueId2 : null
      }
      
      await ProjectService.addQuestions(data).then((res) => {
        const data = res.data;

        if(data.status === true){
          const deepLink = `localhost:3000/sign-up?id=${uniqueId}`
          setItem("deep_link", deepLink)
          setItem("project_questions", JSON.stringify(questionList))
          navigate("/deep-link");
        }
        else{
          toast.error(data.message);
        }
      }).catch((e) => {
        toast.error(e);
      })
    }
  }
  
  const checkInputs = () => {
    const projectName = questionList.find((obj) => obj.questionId === 'name of project');
    const email = questionList.find((obj) => obj.questionId === "company's email");

    if((projectName.answer === '' || email.answer === '')){
      toast.warning("Project name, email requried!");
      return false;
    }
    else{
      return true;
    }
  }

  const addQuestion = (questionDetails) => {
    const list = [...questionList];
    list.push(questionDetails);
    setQuestionList(list);
  }

  const removeItemFromStorage = () => {
    removeItem("project_questions");
    removeItem("deep_link");
    removeItem("add_project_with_campaign");
    navigate('/')
  }

  return(
    <div className='main-container'>
      <Header 
        onHandleClick={removeItemFromStorage}
      />

      <AddQuestionModal 
        show={showAddQuestionModal}
        setModal={setShowAddQuestionModal}
        handleAddQuestion={addQuestion}
        withCampaign={false}
      />
      
      <div className='project-questions'>
        <div className='d-flex align-items-center justify-content-between add-question-container'>
          <div className='title'>
            Questions for the project
          </div>
          <button
            className='add-question-button'
            onClick={() => setShowAddQuestionModal(true)}
          >
            <AddIcon />
            Add
          </button>
        </div>

        <div className='questions'>
          <QuestionsContainer 
            questions={questionList}
            setQuestions={setQuestionList}
          />
        </div>

        <button
          className='common-button generate-link-button'
          onClick={submitQuestions}
          // disabled={checkInputs()}
        >
          <LinkIcon />
          Generate Link
        </button>
      </div>
    </div>
  )
}
export default QuestionForProject;