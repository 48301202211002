import "./index.scss";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
import { ReactComponent as RatingStar } from "@assets/icons/Common/rating-star.svg";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
// import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
// import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
// import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
import Status from "@components/common/Status";

const InfluencerCard = ({ details, showStatus }) => {

  const { profile, name } = details
   
  return(
    <div className='recommended-influncer-card position-relative'>
      {/* {
        showStatus &&
        <div className='status-badge'>
          <Status 
            status={details.status}
          />
        </div>
      } */}
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <div className='profile-image-wrapper'>
            <img src="https://picsum.photos/200" alt='' />
          </div>
          <div>
            <div className='name'> {name} </div>
            <div className='influencer-type d-flex align-items-center'>
              {/* <Twitter className='social-icon' /> */}
              {profile.influencerType}
            </div>
          </div>
        </div>
        {/* {!showStatus &&
          <div className='rating-container'>
            <RatingStar />
            {details.rating}
          </div>
        } */}
      </div>

      <div className='matrix-wrapper'>
        <div className='w-100'>
          <div className='label'> Combined Reach </div>
          <div className='value'> 500K </div>
        </div>
        <div className='w-100 d-flex justify-content-end'>
          <div>
            <div className='label'> Crypto Space </div>
            <div className='value'> {profile.cryptoSpace} </div>
          </div>
        </div>
        <div className='w-100  d-flex justify-content-end'>
          <div>
            <div className='label'> Projects </div>
            <div className='value'> {profile.projectsCompleted} </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default InfluencerCard;