import { useState } from 'react';
import "./index.scss";
// import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "@assets/icons/ProjectProfile/link.svg";
import { ReactComponent as Verified } from "@assets/icons/ProjectProfile/verified.svg";
import ArrowRight from "@assets/icons/Common/yellow-right-icon.svg";
import InfluencerSocialModal from "../InfluencerSocialModal";
import ProfileSocialMedia from "@components/common/ProfileSocialMediaLinks";

const InfluencerSocialDetails = ({ influencerDetails, editable, handleChange }) => {

  const [showSocialDetailModal, setShowSocialDetailModal] = useState(false);

  return(
    <>
      <InfluencerSocialModal 
        showModal={showSocialDetailModal}
        setModal={setShowSocialDetailModal}
      />

      <div className='influencer-social-details'>
        <div className='d-flex align-items-center'>
          <input 
            type='text'
            className='profile-edit-input project-name'
            onChange={(e) => handleChange(e.target.value, "name")}
            value={influencerDetails.name}
            placeholder="Influencer name"
            disabled={editable}
          />
          <Verified />
        </div>
        <input 
          type='text'
          className='profile-edit-input project-type d-block'
          onChange={(e) => handleChange(e.target.value, "influencerType")}
          value={influencerDetails.influencerType}
          placeholder="Influencer most engage platform"
          disabled={editable}
        />
        <input 
          type='text'
          className='profile-edit-input project-desc d-block'
          onChange={(e) => handleChange(e.target.value, "skills")}
          value={influencerDetails.skills}
          placeholder="Influencer's skills"
          disabled={editable}
        />
        <div className='d-flex align-items-center margin-top-10 margin-bottom-30'>
          <LinkIcon />
          <input 
            type='text'
            className='profile-edit-input project-link d-block'
            onChange={(e) => handleChange(e.target.value, "websiteUrl")}
            value={influencerDetails.websiteUrl}
            placeholder="Influencer's site url"
            disabled={editable}
          />
        </div>

        <div className='influencer-statics d-flex'>
          <div 
            className='statics-item' 
            onClick={() => setShowSocialDetailModal(true)}
          >
            <div className='statics-item-label'>
              Combined Reach
            </div>
            <div className='statics-item-value d-flex align-items-center'>
              <u>508K</u>
              <img src={ArrowRight} alt='' className='margin-left-8 margin-bottom-2' />
            </div>
          </div>

          <div className='statics-item'>
            <div className='statics-item-label'>
              Interested In
            </div>
            <div className='statics-item-value d-flex align-items-center'>
              NFT’S 
              <div>+4 More</div>
            </div>
          </div>
          
          <div className='statics-item'>
            <div className='statics-item-label'>
              Projects 
            </div>
            <input 
              type='text'
              className='profile-edit-input statics-item-value d-block'
              onChange={(e) => handleChange(e.target.value, "projectsCompleted")}
              value={influencerDetails.projectsCompleted}
              placeholder="Influencer projects"
              disabled={editable}
            />
          </div>
        </div>

        <div className='br'></div>
        
        {
          influencerDetails?.socialMediaUrls &&
            <ProfileSocialMedia 
              socialMediaUrls={influencerDetails.socialMediaUrls}
            />
        }
      </div>
    </>
  )

}
export default InfluencerSocialDetails;