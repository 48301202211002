import axios from "axios";
import config from "../config";

const influencer = {}

const baseUrl = config.baseUrl;

influencer.addQuestions = async (data) => {
   const url = `${baseUrl}/create-influencer`;
   const res = await axios({
      url,
      method : "POST",
      data,
   })

   return res;
}

influencer.getInfluencerListByStatus = async (status) => {
   const url = `${baseUrl}/get-projects-list?status=${status}`;
   const res = await axios({
      url,
      method : "GET",
   })

   return res;
}


influencer.CreateInfluencerProfile = async (data) => {
   const url = `${baseUrl}/create-influencer-profile`;
   const res = await axios({
      url,
      method : "POST",
      data,
   })

   return res;
}

influencer.getInfluencerProfile = async (id) => {
   const url = `${baseUrl}/get-influencer-profile/${id}`;
   const res = await axios({
      url,
      method : "GET"
   })

   return res;
}

influencer.editInfluencerProfile = async (data) => {
   const url = `${baseUrl}/edit-influencer-profile`;
   const res = await axios({
      url,
      method : "POST",
      data
   })

   return res;
}


influencer.influencerList = async () => {
   const url = `${baseUrl}/influencer-list`;
   const res = await axios({
      url,
      method: "GET",
   })
   
   return res;
}


export default influencer;