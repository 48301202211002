import React, { useState, useEffect } from "react";
import UploadIcon from "@assets/icons/Common/upload-icon.svg";
import DateAndTimePicker from "./DateAndTimePicker";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// import localizedFormat from 'dayjs/plugin/localizedFormat';

// dayjs.extend(localizedFormat);

const TweetContent = (props) => {

   const {
      number, 
      data,
      onChangeQuestion, 
      onChangeTweets, 
      setQuestion,  
      deleteQuestion,
      editQuestion,
      startDate,
      endDate,
      onChangeFile,
      onThreadFileChange,
      handleOnContentChange,
      handleAddThread,
      handleThreadContentChange,
      handleChangeDateAndTime,
      removeThread
   } = props;

   const [showOption, setShowOption] = useState(false);
   const [currentTweet, setCurrentTweet] = useState(0);


   return(
      <>
         <div className='question-container' key={number}>
            <div className='d-flex'>
              <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
               />
            </div>

            <div className='d-flex answer-container'>
               <div className='custom-select-wrapper'>
                  <div 
                     className='selected-value-wrapper'
                     onClick={() => setShowOption(!showOption)}
                  >
                     <div>{data.answer ? data.answer : 'Select'}</div>
                     {/* <img src={DownCaret} alt='' /> */}
                  </div>

                  <div className={`option-wrapper ${showOption ? 'show-option' : ''}`}>
                     {data.options !== null && data.options.map((option, i) => ( 
                        <div
                           key={i}
                           className='option'
                           onClick={() => {onChangeTweets(option.value, number); setShowOption(false)}}
                        > 
                           { option.value } 
                        </div>
                     ))}
                  </div>
               </div>
            </div>
            
            <div className='tabs-container tweets-tabs'>
               {
                  data.content?.map((item, ind) => (
                     <div 
                        className={`tab ${ind === currentTweet ? 'active' : ''}`}
                        key={`tab-${ind}`}
                        onClick={() => setCurrentTweet(ind)}
                     >
                        {ind + 1} &nbsp; {ind === 0 ? 'Tweet' : 'Tweets'}
                     </div>
                  ))
               }
            </div>
            
            
            <div className='tweet-content'>
               {data.content?.map((item, ind) => (
                  <div key={ind} className='tweet-item'>
                     {ind === currentTweet && (
                        <>
                           <div className='input-label margin-bottom-0'> Tweet Date </div>
                           <DateAndTimePicker 
                              handleChange={(value) => handleChangeDateAndTime(value, ind, number)}
                              value={item.postDateAndTime !== '' ? dayjs(item.postDateAndTime) : dayjs(startDate)}
                              startDate={startDate}
                              endDate={endDate}
                              number={ind}
                           />

                           <div className='input-label'>Upload Attachment</div>
                           <label className='custom-file-input-wrapper d-block w-100'>
                              <input
                                 type='file'
                                 className='d-none'
                                 accept='.doc, .docx, .xls, .xlsx, .pdf'
                                 onChange={(e) => onChangeFile(e.target.files[0], ind, number)}
                              />
                              <span className='file-upload-button d-flex align-items-center justify-content-start'>
                                 <img src={UploadIcon} alt='' />
                                 <div className='label'>{item.document !== '' ? item.document : 'Upload Attachment'}</div>
                              </span>
                           </label>

                           <div className='input-label'>Add Content</div>
                           <textarea
                              type='text'
                              value={item.content}
                              onChange={(e) => handleOnContentChange(e.target.value, ind, number)}
                              placeholder='Enter Content here'
                              className='content-text-area'
                           />

                           <div
                              className='d-flex align-items-center justify-content-end add-thread-title'
                              onClick={() => handleAddThread(ind, number)}
                           >
                              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                                 <path
                                    d='M16.4582 10.0003C16.4582 10.3453 16.1782 10.6253 15.8332 10.6253H10.6248V15.8337C10.6248 16.1787 10.3448 16.4587 9.99984 16.4587C9.65484 16.4587 9.37484 16.1787 9.37484 15.8337V10.6253H4.1665C3.8215 10.6253 3.5415 10.3453 3.5415 10.0003C3.5415 9.65533 3.8215 9.37533 4.1665 9.37533H9.37484V4.16699C9.37484 3.82199 9.65484 3.54199 9.99984 3.54199C10.3448 3.54199 10.6248 3.82199 10.6248 4.16699V9.37533H15.8332C16.1782 9.37533 16.4582 9.65533 16.4582 10.0003Z'
                                    fill='#9371EE'
                                 />
                              </svg>

                              Add Thread
                           </div>
                        </>
                     )}

                     {item.threads.length > 0 && ind === currentTweet && (
                        <>
                           {item.threads.map((obj, threadInd) => (
                              <div key={threadInd}>
                                 <div className='d-flex align-items-center justify-content-between margin-top-36'>
                                    <div className='input-label mt-0'>Thread {threadInd + 1}</div>
                                    <div 
                                       className='remove-thread'
                                       onClick={() => removeThread(threadInd, ind, number)}
                                    >-</div>
                                 </div>
                                 <textarea
                                    type='text'
                                    value={obj.content}
                                    onChange={(e) => handleThreadContentChange(e.target.value, ind, threadInd, number)}
                                    placeholder='Enter Content here'
                                    className='content-text-area'
                                 />

                                 <div className='input-label'>Upload Thread {threadInd + 1} Attachment</div>
                                 <label className='custom-file-input-wrapper d-block w-100'>
                                    <input
                                       type='file'
                                       className='d-none'
                                       accept='.doc, .docx, .xls, .xlsx, .pdf'
                                       onChange={(e) => onThreadFileChange(e.target.files[0], ind, threadInd, number)}
                                    />
                                    <span className='file-upload-button d-flex align-items-center justify-content-start'>
                                       <img src={UploadIcon} alt='' />
                                       <div className='label'>
                                          {obj?.document !== '' ? obj.document : 'Upload Attachment'}
                                       </div>
                                    </span>
                                 </label>
                              </div>
                           ))}
                        </>
                     )}
                  </div>
               ))}
            </div>
         </div>
      </>
   )
}
export default TweetContent;