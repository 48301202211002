import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import InfluencerOnboardingDetailHeader from "@components/common/influencerOnboardingDetailsHeader";
import QuestionsWrapper from '@components/ProjectOnboardingDetails/QuestionsWrapper';
import { getItem, removeItem } from "@utils/getAndSetDataToStorage";
import InfluencerService from "@services/influencer";

const InfluencerOnboardingDetails = () => {

  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [influencerDetails, setInfluencerDetails] = useState('');

  useEffect(() => {
    const onBoardingDetails = getItem('onboarding_influencer_details', true);
    const answered  = onBoardingDetails.influencerQuestions.filter((obj) => obj.answer !== '');
    const questions = onBoardingDetails.influencerQuestions.filter((obj) => obj.answer === '');
    setAnsweredQuestions(answered);
    setQuestionList(questions);
    setInfluencerDetails(onBoardingDetails);
  }, [])

  const createProfile = async () => {
    const allQuestions = [...answeredQuestions, ...questionList]; 
    const t1 = allQuestions.find((obj) => obj.questionId === 'tweet price for 1-3')
    const t3 = allQuestions.find((obj) => obj.questionId === 'tweet price for 4-6')
    const t8 = allQuestions.find((obj) => obj.questionId === 'tweet price for 8-10')
    const prices = [
      {
        platform: "twitter",
        tweet1to3: t1.answer,
        tweet4to6: t3.answer,
        tweet8to10: t8.answer,
      }
    ]

    const data = {
      influencerId: influencerDetails.id,
      influencerQuestions: JSON.stringify(allQuestions),
      prices: prices
    }
    
    await InfluencerService.CreateInfluencerProfile(data).then((res) => {
      if(res.status === 200){
        navigate("/influencer-profile")
      }
    }).catch((e) => {
      const errors = e.response.data.message;
      alert(errors)
    })
  }

  const checkInputs = () => {
    const isQuestionsFilled = questionList.every((obj) => obj.answer !== '');
    const isAnsFilled = answeredQuestions.every((obj) => obj.answer !== '');
    if(isQuestionsFilled && isAnsFilled) return false
    else return true;
  }

  const removeDataFromStorage = () => {
    removeItem("onboarding_influencer_details");
    removeItem("influencer_id");
    navigate("/manage-onboarding")
  }

  return(
    <div className='main-container'>
      <Header 
        onHandleClick={removeDataFromStorage}
      />

      <div className='onboarding-details-page'>
        
        <InfluencerOnboardingDetailHeader 
          data={influencerDetails}
        />
        
        <div className='title'>
          Onboarding Details
        </div>
          
        {
          answeredQuestions?.length > 0 &&
          <QuestionsWrapper 
            questions={answeredQuestions}
            setQuestion={setAnsweredQuestions}
            title="Questions Answered"
          />
        }

        {
          questionList?.length > 0 &&
          <QuestionsWrapper 
            questions={questionList}
            setQuestion={setQuestionList}
            title="Questions Pending"
          />
        }

        <button
          onClick={createProfile}
          disabled={checkInputs()}
          className='common-button margin-top-30'
        >
          Create Profile
        </button>
      </div>
    </div>
  )
}
export default InfluencerOnboardingDetails;