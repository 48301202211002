import "./index.scss";
import { useNavigate } from "react-router-dom";
import { setItem } from "@utils/getAndSetDataToStorage";
import Close from "@assets/icons/Common/close-icon.png";

const AddProjectModal = ({ showModal, setModal }) => {

  const navigate = useNavigate();
  const setOption = (value) => {
    setModal(false)
    setItem('add_project_with_campaign', value);
    navigate('/questions-for-project')
  }

  return(
    <div className={`custom-modal add-project-modal ${showModal ? 'show-modal' : ''}`}>
      <div className='modal-card-wrapper position-relative'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='title'>
            Add a Project
          </div>
          <button className='close-modal-button' onClick={() => setModal(false)}>
            <img src={Close} alt='' />
          </button>
        </div>

        <div 
          className='option'
          onClick={() => {setOption(true)}}
        > With Marketing Campaign </div>
        <div 
          className='option'
          onClick={() => {setOption(false)}}
        > Without Marketing Campaign </div>
      </div>
    </div>
  )
}
export default AddProjectModal;