import "./index.scss"
import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';

const FileInput = (props) => {
   
   const { 
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      setQuestion, 
      deleteQuestion,
      editQuestion
   } = props;

   return(
      <>
         <div className='question-container' key={number}>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
               />
            </div>

            <div className='d-flex answer-container'>
               <button 
                  className={`boolean-button ${data.answer === 'yes' ? 'active' : ''}`}
                  onClick={() => onChangeAnswer('yes', number)}
               >
                  Yes
               </button>
               <button
                  className={`boolean-button ${data.answer === 'no' ? 'active' : ''}`}
                  onClick={() => onChangeAnswer('no', number)}
               >
                  No
               </button>

               <button 
                  className={`edit-button position-relative ${!editQuestion ? 'active' : ''}`}
                  onClick={() => setQuestion(number)}
                  >
                  <Edit />
               </button>
               <button 
                  className='delete-button'
                  onClick={() => deleteQuestion(number)}
               >
                  <Delete />
               </button>
            </div>
         </div>
      </>
   )
}
export default FileInput;