import { useState, useEffect } from "react";
import "./deepLink.scss";
import { useNavigate } from 'react-router-dom';
import Header from "@components/common/Header";
import { ReactComponent as Success } from '@assets/icons/DeepLink/success.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/DeepLink/link.svg';
import { getItem, removeItem } from "@utils/getAndSetDataToStorage";

const DeepLink = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [deepLink, setDeepLink] = useState('');

  const copyToClipboard = (deepLink) => {
    setCopied(true); 
    navigator.clipboard.writeText(deepLink);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  useEffect(() => {
    const link = getItem("deep_link");
    setDeepLink(link);
  }, [])

  const removeItemFromStorage = () => {
    removeItem("project_questions");
    removeItem("influencer_questions");
    removeItem("deep_link");
    removeItem("add_project_with_campaign");
    navigate("/")
  }

  return(
    <div className="main-container">
      <Header />
      
      <div className='deep-link'>
        <div></div>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Success />

          <div className='title'>
            Deep link generated
          </div>

          <div className='desc'>
            An email has been sent to the company’s email. You can check the status of the case on the onboarding request section
          </div>

          <button 
            className='copy-button'
            onClick={() => copyToClipboard(deepLink)}  
          >
            <LinkIcon />
            {copied ? 'Copied!' : 'Copy Link' }
          </button>
        </div>

        <button
          className='common-button'
          onClick={removeItemFromStorage}
        >
          Back Home
        </button>
      </div>
    </div>
  )
}
export default DeepLink;