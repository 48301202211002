import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';
import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";

const SocialMediaUrlsInput = (props) => {

   const { 
      number,
      data,
      ChangeUrl,
      deleteUrl
   } = props;

   return(
      <>
         <div className='question-container'>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  value={data.question}
                  disabled={true}
               />
            </div>
            
            {
               data.socialMediaUrls.map((item, ind) => (
                  <div key={ind}>
                     <div className='d-flex answer-container'>
                        <div className='position-relative flex-grow-1 margin-right-4'>
                           <input 
                              type='text'
                              className='answer-input social-media-url-input'
                              onChange={(e) => ChangeUrl(e.target.value, number, ind)}
                              value={item.url}
                              placeholder={item.name}
                           />
                           <div className='social-icon-wrapper'>
                              {item.name === 'twitter' && <Twitter />}
                              {item.name === 'instagram' && <Instagram />}
                              {item.name === 'linkedIn' && <LinkedIn />}
                              {item.name === 'facebook' && <Facebook />}
                              {item.name === 'youtube' && <Youtube />}
                           </div>
                        </div>
                        <button 
                           className='delete-button'
                           onClick={() => deleteUrl(number, ind)}
                        >
                           <Delete />
                        </button>
                     </div>
                  </div>
               ))
            }
         </div>
      </>
   )
}
export default SocialMediaUrlsInput;