import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";


const CustomEventCalender = ({ date, setDate, highlightDates }) => {
   // console.log(highlightDates)
   return(
      <div className={`custom-event-calender`}>
         <DatePicker
            dateFormat="MM-DD-YYYY"
            selected={date}
            onChange={(date) => setDate(date)}
            inline
            // highlightDates={highlightDates}
         />
      </div>
   )
}
export default CustomEventCalender;