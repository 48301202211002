import { useState, useRef  } from 'react';
import Title from '@components/common/TitleContainer';
import { ReactComponent as SearchIcon } from "@static/assets/icons/Common/search-icon.svg";
import { ReactComponent as FilterIcon } from "@static/assets/icons/Common/filter-icon.svg";
import { ReactComponent as CrossIcon } from "@static/assets/icons/Common/cross-icon.svg";

// const NoOfTweetsFilter = [
//   { value: '1-3', label: '1-4 Tweets', isChecked: false },
//   { value: '4-6', label: '4-6 Tweets', isChecked: false },
//   { value: '6-8', label: '6-8 Tweets', isChecked: false },
//   { value: '8-10', label: '8-10 Tweets', isChecked: false },
// ]

const list = [
  {
    name: "Reach",
    list: [
      { value: '5K-20k', label: '5K-20k', isChecked: false },
      { value: '20k-50k', label: '20k-50k', isChecked: false },
      { value: '50K-100k', label: '50K-100k', isChecked: false },
      { value: '100k-500k', label: '100k-500k', isChecked: false },
    ]
  },
  {
    name: "Tags",
    list: [
      { value: 'Defi', label: 'Defi', isChecked: false },
      { value: 'Dexs', label: 'Dexs', isChecked: false },
      { value: 'Trading', label: 'Trading', isChecked: false },
      { value: 'Airdrop', label: 'Airdrop', isChecked: false },
      { value: 'Defi', label: 'Defi', isChecked: false },
      { value: 'Dexs', label: 'Dexs', isChecked: false },
      { value: 'Trading', label: 'Trading', isChecked: false },
      { value: 'Airdrop', label: 'Airdrop', isChecked: false },
    ]
  }
]

const SearchAndFilterBar = () => {

  const [showFilter, setShowFilter] = useState(false);
  const [filterList, setFilterList] = useState(list);
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [showFilterCard, setShowFilterCard] = useState(false);

  const handleChangeFilter = (filterInd, ind) => {
    const newFilters = [...filterList];
    newFilters[ind].list[filterInd].isChecked = !newFilters[ind].list[filterInd].isChecked;
    setFilterList(newFilters)
  }

  const applyFilter = () => {
    let newFilters = [...filterList];
    setAppliedFilter(newFilters);
    handleHideModal()
  }

  const removeFilter = (filterInd, ind) => {
    const newFilters = [...filterList];
    const newFilterObj = {...newFilters[ind]};
    const newList = [...newFilterObj.list];
    newList[filterInd].isChecked = false;
    newFilters[ind] = {...newFilterObj};
    setFilterList(newFilters)
    setAppliedFilter(newFilters)
  }

  const handleOpenModal = () => {
    setShowFilter(true)
    setTimeout(() => {
      setShowFilterCard(true)
    }, 500);
  }
  
  const handleHideModal = () => {
    setShowFilterCard(false)
    setTimeout(() => {
      setShowFilter(false);
    }, 500);
  }

  return (
    <>
      <div className='search-and-filter-bar'>

        <div className='title'> Recommended Influencers </div>
        <div className='d-flex'>
          <div className='search-input-wrapper d-flex align-items-center'>
            <SearchIcon />
            <input 
              type="text"
              className="search-input"
              placeholder="Search for onboarded influencer"
            />
          </div>

          <button className='filter-button' onClick={handleOpenModal}>
            <FilterIcon />
          </button>
        </div>
        
        {
          appliedFilter.length > 0 &&
          <div className='filter-selected-items'>
            {
              appliedFilter.map((obj, ind) => (
                obj.list.map((item, number) => (
                  item.isChecked &&
                  <div className='item-box'> 
                    <span> {item.label} </span>
                    <span onClick={() => removeFilter(number, ind)}>
                      <CrossIcon /> 
                    </span>
                  </div>
                ))
              ))
            }
          </div>
        }
      </div>

        <div className={`filter-wrapper ${showFilter ? 'active' : ''}`} onClick={handleHideModal}>
          <div className={`filter-wrapper-card ${showFilterCard ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
            <div className='close-filter-tab'></div>
            <div className='d-flex align-items-center justify-content-between margin-bottom-30'>
              <div className='filter-title'> Filter </div>
              <div className='reset'> Reset </div>
            </div>

            {
              filterList.map((item, ind) => (
                <div key={`filters-${ind}`}>
                  <div className='filter-section-title'> {item.name} </div>
                  <div className='check-box-wrapper'>
                    {
                      item.list.map((filter, number) => (
                        <div key={`f-${number}`}>
                          <input 
                            id={`checkbox-${filter.label}-${number}`}
                            className="checkbox-custom" 
                            type="checkbox" 
                            checked={filter.isChecked} 
                            onChange={() => handleChangeFilter(number, ind)} 
                          />
                          <label 
                            htmlFor={`checkbox-${filter.label}-${number}`} 
                            className="checkbox-custom-label"
                          >
                            {filter.label}
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }

            <button className='filter-apply-button' onClick={applyFilter}> Apply </button>
          </div>
        </div>
      
    </>
  )
}
export default SearchAndFilterBar;