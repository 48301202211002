import "./index.scss";
// import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "@assets/icons/ProjectProfile/link.svg";
import { ReactComponent as Verified } from "@assets/icons/ProjectProfile/verified.svg";
import ProfileSocialMedia from "@components/common/ProfileSocialMediaLinks";

const ProjectSocialDetails = ({ project = {}, editable, handleChange }) => {

  const {
    name = "",
    projectType = "",
    tagline = "",
    websiteUrl = "",
    fundRaised = "",
    establishedYear = "",
    companyValuation = "",
    socialMediaUrls = null,
  } = project;

  return(
    <div className='profile-social-details'>
      <div className='d-flex align-items-center'>
        <input 
          type='text'
          className='profile-edit-input project-name'
          onChange={(e) => handleChange(e.target.value, "name")}
          value={project.name}
          placeholder="Project name"
          disabled={editable}
        />
        <Verified />
      </div>
      <input 
        type='text'
        className='profile-edit-input project-type'
        onChange={(e) => handleChange(e.target.value, "projectType")}
        value={project.projectType}
        placeholder="Project type"
        disabled={editable}
      />
      <input 
        type='text'
        className='profile-edit-input project-desc'
        onChange={(e) => handleChange(e.target.value, "tagline")}
        value={project.tagline}
        placeholder="Tagline"
        disabled={editable}
      />

      <div className='d-flex align-items-center margin-top-10 margin-bottom-30'>
        <LinkIcon />
        <input 
          type='text'
          className='profile-edit-input project-link'
          onChange={(e) => handleChange(e.target.value, "websiteUrl")}
          value={project.websiteUrl}
          placeholder="Website URL"
          disabled={editable}
        />
      </div>

      <div className='project-statics d-flex justify-content-between'>
        <div className='statics-item'>
          <div className='statics-item-label'>
            Raised
          </div>
          <input 
            type='text'
            className='profile-edit-input statics-item-value'
            onChange={(e) => handleChange(e.target.value, "fundRaised")}
            value={project.fundRaised}
            placeholder="Fund raised"
            disabled={editable}
          />
        </div>
        <div className='statics-item d-flex justify-content-center'>
          <div>
            <div className='statics-item-label'>
              Started
            </div>
            <input 
              type='text'
              className='profile-edit-input statics-item-value'
              onChange={(e) => handleChange(e.target.value, "establishedYear")}
              value={project.establishedYear}
              placeholder="Started"
              disabled={editable}
            />
          </div>
        </div>
        <div className='statics-item d-flex justify-content-end'>
          <div>
            <div className='statics-item-label'>
              Valuation
            </div>
            <input 
              type='text'
              className='profile-edit-input statics-item-value'
              onChange={(e) => handleChange(e.target.value, "companyValuation")}
              value={project.companyValuation}
              placeholder="Valuation"
              disabled={editable}
            />
          </div>
        </div>
      </div>

      <div className='br'></div>
      
      {
        project?.socialMediaUrls &&
          <ProfileSocialMedia 
            socialMediaUrls={project.socialMediaUrls}
          />
      }
    </div>
  )

}
export default ProjectSocialDetails;