// import './App.css';
import RoutesConfig from './RoutesConfig';
import "./styles/index.scss";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line
import AxiosInterceptor from "./services/interceptor";

function App() {

  return (
    <>
      <RoutesConfig />
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
