import { ReactComponent as Edit } from '@assets/icons/QuestionsForProject/edit.svg';
import { ReactComponent as Delete } from '@assets/icons/QuestionsForProject/trash.svg';

const TextInput = (props) => {

   const { 
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      setQuestion, 
      deleteQuestion,
      editQuestion
   } = props;
   
   return(
      <>
         <div className='question-container'>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
                  disabled={editQuestion}
               />
            </div>

            <div className='d-flex answer-container'>
               <input 
                  type={data.type === 'number' ? 'number' : 'text'}
                  className='answer-input'
                  onChange={(e) => onChangeAnswer(e.target.value, number)}
                  value={data.answer}
                  placeholder='Enter your answer'
               />

               <button 
                  className={`edit-button position-relative ${!editQuestion ? 'active' : ''}`}
                  onClick={() => setQuestion(number)}
                  >
                  <Edit />
                  {/* {
                     data.showOptions ?
                     <div className='edit-option-wrapper'>
                        <div 
                           className='option'
                           onClick={() => setQuestion(number)}
                        >
                           {data.editQuestion ? 'Question edited'  : 'Edit question' }
                        </div>
                        <div 
                           className='option'
                           onClick={() => setAnswer(number)}
                        >
                           Answer question
                        </div>
                     </div>
                     :
                     null
                  } */}
               </button>
               <button 
                  className='delete-button'
                  onClick={() => deleteQuestion(number)}
               >
                  <Delete />
               </button>
            </div>
         </div>
      </>
   )
}
export default TextInput;