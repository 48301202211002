import "./contentCalender.scss";
import { useState } from "react";
import SelectedInfluencerCard from "@components/common/InfluencerDetailCard";
import ProfileImgWrapper from "@components/common/Header/profileImg";
import { ReactComponent as Instagram } from "@assets/icons/Common/instagram.svg";
import { ReactComponent as Twitter } from "@assets/icons/Common/twitter.svg";
// import { ReactComponent as Youtube } from "@assets/icons/Common/youtube.svg";
// import { ReactComponent as Facebook } from "@assets/icons/Common/facebook.svg";
// import { ReactComponent as LinkedIn } from "@assets/icons/Common/linkedIn.svg";
import { ReactComponent as BackArrow } from "@assets/icons/Common/back-arrow.svg";
import MarketingContent from "@components/ContentCalender/MarketingContent";


const selectedInfluencers = [
  {
    name: 'John Doe',
    mainPlatform: 'twitter',
    budget: "4200",
    image: "https://picsum.photos/200/300"
  }
]

const ContentCalender = () => {

  const [markAsCompleted, setMarkAsCompleted] = useState(false);

  return(
    <div className='main-container content-calender'>

      <div className='content-calender-header'>
        <button className='back-button'>
          <BackArrow />
        </button>

        <ProfileImgWrapper />
      </div>

      <div className='title'> Content Calendar for </div>

      <SelectedInfluencerCard 
        data={selectedInfluencers[0]}
        showCalendarLink={false}
      />

      <div className='border-bottom'></div>

      <div className='d-flex align-items-center justify-content-between'>
        <div className='selected-date'>17th August, 2025</div>
        <div className='d-flex align-items-center'>
          <div className='platform-tab'>
            <Twitter />
            <div>2 &nbsp;Tweets</div>
          </div>
          <div className='platform-tab'>
            <Instagram />
            2  &nbsp; Stories
          </div>
        </div>
      </div>

      <MarketingContent 
        data='data'
        platform='twitter'
        completedMark={markAsCompleted}
        setMark={setMarkAsCompleted}
      />
    </div>
  )
}
export default ContentCalender;