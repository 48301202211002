import Header from "@components/common/Header";
// import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import CampaignStatusDetails from "@components/PendingCampaign/CampaignStatusDetails";
import Status from "@components/common/Status";
import InfluencerCard from "@components/RecommendedInfluencers/influncerCard";
import QuestionWithAnswer from "@components/common/QuestionWithAnswer";

const CamapaignData = {
   logo: '',
   name: 'Unore',
   date: '10',
   amount: '8800',
   paymentReceived: '24th oct 2023',
   status: 'pending',
   campaignRange: '10th April 2023 - 18th April 2023 '
}

const selectedInfluencers = [
  {
    profileImage: 'https://picsum.photos/200',
    name: 'John Doe',
    influncerType : "twitter",
    rating: 4.5,
    combinedReach: "408k",
    engagement: "24%",
    project: "48",
    status: 'success'
  },
  {
    profileImage: 'https://picsum.photos/200',
    name: 'John Doe',
    influncerType : "twitter",
    rating: 4.5,
    combinedReach: "408k",
    engagement: "24%",
    project: "48",
    status: 'pending'
  },
]

const QuestionAnswerList = [
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
  {
    question: "What's the name of your project ?",
    answer: "UnoRe is a risk trading reinsurance platform for the community to invest and trade in risk and receive sizable returns on their investments."
  },
]


const PendingCampaign = () => {

   return(
      <div className='main-container'>
         <Header 
            back='/campaign-list'
         />
         <div className='pending-campaign-page'>
            <CampaignStatusDetails 
               data={CamapaignData}
            />

            <div className='payment-status-card'>
               <div>
                  <div className='amount'>$8,800</div>
                  <div className='date'>Recieved on 24th oct 2023</div>
               </div>
               <Status 
                  status='success'
                  text='Payment Recieved'
                  icon={true}
                  customClass='h-12'
               />
            </div>

            <div className='title margin-bottom-13'> 2 Influencers </div>

            {
               selectedInfluencers.map((item, key) => (
                  <InfluencerCard 
                     key={key}
                     details={item}
                     showStatus={true}
                  />
               ))
            }

            <div className='title margin-bottom-20 margin-top-30'> Campaign Details </div>

            <div className='questions-answer-wrapper'>
            {
               QuestionAnswerList?.map((item, ind) => (
                  <QuestionWithAnswer 
                     data={item}
                     key={ind}
                     index={ind}
                  />
               ))
               }
            </div>
         </div>
      </div>
   )
}
export default PendingCampaign;