import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "influencer-admin-75850.firebaseapp.com",
  projectId: "influencer-admin-75850",
  storageBucket: "influencer-admin-75850.appspot.com",
  messagingSenderId: "582278747683",
  appId: "1:582278747683:web:989d1a2d61d803f9a40396"
};
export default firebaseConfig;
// // Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);