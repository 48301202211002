import axios from "axios";
import config from "../config";

const project = {}

const baseUrl = config.baseUrl;

project.addQuestions = async (data) => {
   const url = `${baseUrl}/create-project`;
   const res = await axios({
      url,
      method : "POST",
      data,
   })

   return res;
}
project.uploadFile = async (data) => {
   const url = `${baseUrl}/upload-file`;
   const res = await axios({
      url,
      method : "POST",
      data,
      headers: {
         'Content-Type': 'multipart/form-data', // Important to set the correct content type
      },
   })

   return res;
}

project.downloadFile = async (filename) => {
   const url = `${baseUrl}/download?filename=${filename}`;
   const res = await axios({
      url,
      method: "GET",
      responseType: 'blob', 
   })

   return res;
}

project.getProjectAndInfluencerListByStatus = async (status, listOf) => {
   const url = `${baseUrl}/get-projects-list?status=${status}&type=${listOf}`;
   const res = await axios({
      url,
      method : "GET",
   })

   return res;
}

project.updateProjectProfile = async (data) => {
   const url = `${baseUrl}/create-project-profile`;
   
   const res = await axios({
      url,
      method : "POST",
      data,
   })

   return res;
}

project.getProjectProfile = async (id) => {
   const url = `${baseUrl}/get-project-profile/${id}`;
   const res = await axios({
      url,
      method : "GET"
   })

   return res;
}


project.editProjectProfile = async (data) => {
   const url = `${baseUrl}/edit-project-profile`;
   const res = await axios({
      url,
      method : "POST",
      data
   })

   return res;
}

export default project;