import "./index.scss";

const PasteLinkModal = (props) => {

   const {
      show,
      handleChange,
      link,
      setModal
   } = props;

   return(
      <>
         <div className={`custom-modal paste-link-modal ${show ? 'show-modal' : ''}`}>
            <div className='modal-card-wrapper'>
               <div className='title'>Paste Tweet Link</div>
               <textarea 
                  type='text'
                  value={link}
                  onChange={(e) => handleChange(e.target.value)}
                  placeholder='Paste tweet link here'
                  className='link-input'
               />
               <div className='d-flex'>
                  <button
                     type='button' 
                     className='cancel-button'
                     onClick={() => setModal(false)}
                  >
                     Cancle
                  </button>
                  <button 
                     className='complete-button'
                     disabled={link === ''}
                     onClick={() => setModal(false)}
                  >
                     Mark as complete
                  </button>
               </div>
            </div>

         </div>
      </>
   )
}
export default PasteLinkModal;