import { useState } from "react";
import Caret from '@assets/icons/ProjectOnboarding/down-caret.png';
import AddQuestionModal from "@components/common/AddQuestionModal";
import QuestionsContainer from "@components/common/QuestionsContainer";

const QuestionWrapper = ({ questions, setQuestion, title, campaignDuration }) => {
  const [showQuestions, setShowQuestions] = useState(false);

  return(
    <div className={`questions-content-dropdown ${showQuestions ? 'active' : ''} ${title === 'Questions Pending' ? "border-bottom-none" : ''}`} >
      <div 
        className='questions-content-dropdown-title'
        onClick={() => setShowQuestions(!showQuestions)}
      >
        {title}
        <img src={Caret} alt='' />
      </div>

      <div className='questions'>
        <QuestionsContainer 
          questions={questions}
          setQuestions={setQuestion}
          campaignDuration={campaignDuration}
        />
      </div>
    </div>
  )
}
export default QuestionWrapper;