import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './views/Home';
import QuestionForProject from './views/Project/QuestionForProject';
import DeepLink from './views/DeepLink';
import AnsweredQuestions from './views/AnsweredQuestions';
import ProjectProfile from './views/Project/ProjectProfile';
import ProposalSent from './views/ProposalSent';
import RecommendedInfluencers from './views/Influencer/RecommendedInfluencers';
import ReviewProposal from "./views/Influencer/ReviewProposal";
import CampaignsList from "./views/Campaigns/CampaignList";
import CampaignsDetails from "./views/Campaigns/CampaignsDetails";
import ContentCalender from "./views/Campaigns/ContentCalender";
import ManageOnboarding from "./views/ManageOnboarding";
import ProjectOnboardingDetails from "./views/Project/ProjectOnboardingDetails";
import InfluencerOnboardingDetails from "./views/Influencer/InfluencerOnboardingDetails";
import CampaignInfo from "./views/Campaigns/CampaignInfo";
import MeetingRequest from "./views/MeetingRequest";
import MeetingCalender from "./views/MeetingCalender";
import PendingCampaign from "./views/Campaigns/PendingCampaign";
import InfluencerProfile from "./views/Influencer/InfluencerProfile";
import QuestionForInfluencer from "./views//Influencer/QuestionForInfluencer";
import SearchProfile from "./views/SearchProfile";
import CampaignQuestions from "./views/Campaigns/CampaignQuestions";


// Auth
import SignIn from "./views/Auth/SignIn";
// import Signup from "./views/Auth/SignUp";
// import SignUpWithEmailAndPassword from "./views/Auth/SignUpWithEmail";

const RoutesConfig = () => (
  <Router>
    <Routes>
      {/* Auth */}
      {/* <Route exact path="/sign-up" element={<Signup />} /> */}
      {/* <Route exact path="/signup-with-email-password" element={<SignUpWithEmailAndPassword />} /> */}
      <Route exact path="/sign-in" element={<SignIn />} />


      {/* App */}
      <Route exact path="/" element={<Home />} />
      <Route exact path="/questions-for-project" element={<QuestionForProject />} />
      <Route exact path="/deep-link" element={<DeepLink />} />
      <Route exact path="/received-answer" element={<AnsweredQuestions />} />
      <Route exact path="/project-profile" element={<ProjectProfile />} />
      <Route exact path="/proposal-sent" element={<ProposalSent />} />
      <Route exact path="/recommended-influencers" element={<RecommendedInfluencers />} />
      <Route exact path="/review-proposal" element={<ReviewProposal />} />
      <Route exact path="/campaign-list" element={<CampaignsList />} />
      <Route exact path="/campaign-detail" element={<CampaignsDetails />} />
      <Route exact path="/content-calender" element={<ContentCalender />} />
      <Route exact path="/manage-onboarding" element={<ManageOnboarding />} />
      <Route exact path="/update-campaign" element={<CampaignQuestions />} />
      <Route exact path="/project-onboarding" element={<ProjectOnboardingDetails />} />
      <Route exact path="/influencer-onboarding" element={<InfluencerOnboardingDetails />} />
      <Route exact path="/campaign-info" element={<CampaignInfo />} />
      <Route exact path="/meeting-request" element={<MeetingRequest />} />
      <Route exact path="/meeting-calender" element={<MeetingCalender />} />
      <Route exact path="/pending-campaign" element={<PendingCampaign />} />
      <Route exact path="/influencer-profile" element={<InfluencerProfile />} />
      <Route exact path="/question-for-influencer" element={<QuestionForInfluencer />} />
      <Route exact path="/profiles" element={<SearchProfile />} />
    </Routes>
  </Router>
);
export default RoutesConfig;