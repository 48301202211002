import './index.scss';
import UpArrow from '@assets/icons/dashboard/up-green-arrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

const DashboardCards = ({ data }) => {

  return(
    <Swiper
      spaceBetween={8}
      slidesPerView={2}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      className='dashboard-cards-carousel'
    >
      {
        data.map((item, ind) => (
        <SwiperSlide key={`card-${ind}`}>
          <div className='dashboard-card-container'>
            <div className='icon-wrapper'>
              <img src={item.icon} alt='' />
            </div>

            <div className='title'> {item.label} </div>
            <div className='number'> {item.value} </div>
            <div className='monthly-statics'>
              <img src={UpArrow} alt='' />
              <span> {item.appreciation} </span>&nbsp;
              from last month
            </div>
          </div>
        </SwiperSlide>
        ))
      }
    </Swiper>
  )
}
export default DashboardCards;