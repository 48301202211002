import { useState, useEffect } from 'react';
import "./index.scss";
import Calendar from 'react-calendar';
import Close from "@assets/icons/Common/close-icon.png";
import CalenderIcon from "@assets/icons/Common/calender-icon.svg";
import ConvertDateToLocal from "@utils/convertDateAndTimeToLocal";

const DateRangePicker = (props) => {

   const { 
      number, 
      data,
      onChangeQuestion, 
      onChangeAnswer, 
      editQuestion,
      showModal,
      setModal
   } = props;

   const [minDate, setMinDate] = useState(new Date());
   const [startDate, setStartDate] = useState('');
   const [endDate, setEndDate] = useState('');
   const [details, setDetails] = useState('')

   useEffect(() => {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      setMinDate(currentDate);

      const startTime = ConvertDateToLocal(data.answer[0]);
      const endTime = ConvertDateToLocal(data.answer[1]);

      setStartDate(startTime);
      setEndDate(endTime);

      const newData = { ...data, answer: [startTime, endTime] };
      setDetails(newData)
   }, [data.answer])


   return(
      <>
         <div className={`date-picker-modal ${showModal ? 'active' : ''}`}>
            <div className='wrapper'>
               <div className='d-flex align-item-center justify-content-between w-100 margin-bottom-20'>
                  <div className='title'>
                     Select Date Range
                  </div>
                  <button 
                     className='close-modal-button' 
                     onClick={() => setModal(false)}
                  >
                     <img src={Close} alt='' />
                  </button>
               </div>
               <div className='calendar-container'>
                  <Calendar
                     onChange={(value) => {onChangeAnswer(value, number); setModal(false)}}
                     value={details.answer}
                     selectRange={true}
                     minDate={minDate}
                     className='custom-date-range-picker'
                  />
               </div>
            </div>
         </div>

         <div className='question-container'>
            <div className='d-flex'>
               <div className='question-number'>
                  {number + 1}.
               </div>
               <input 
                  type='text'
                  className='question'
                  onChange={(e) => onChangeQuestion(e.target.value, number)}
                  value={data.question}
                  disabled={editQuestion}
               />
            </div>

            <div className='date-label'>
               Campaign starting date
            </div>

            <div 
               className='date-text-wrapper'
               onClick={() => setModal(true)}
            >
               <img src={CalenderIcon} alt='' />
               {startDate} 
            </div>

            <div className='date-label'>
               Campaign ending date
            </div>

            <div 
               className='date-text-wrapper'
               onClick={() => setModal(true)}
            > 
               <img src={CalenderIcon} alt='' />
               {endDate} 
            </div>
         </div>
      </>
   )
}
export default DateRangePicker;