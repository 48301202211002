import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "@components/common/Header";
import SearchIcon from '@assets/icons/dashboard/search-icon.svg';
import SearchProfileCard from './SearchProfileCard';
import ProjectService from "@services/project";
import { removeItem } from "@utils/getAndSetDataToStorage";

const Tabs = [ 'Companies', 'Influencer'];

const SearchProfile = () => {
   
   const navigate = useNavigate();
   const [tab,setTab] = useState("Companies");
   const [search, setSearch] = useState("");
   const [projects, setProjects] = useState([]);
   const [influencers, setInfluencers] = useState([]);
   const [profiles, setProfiles] = useState([]);

   const handleOnSearchChange = (query) => {
      setSearch(query);
      const filtered = profiles.filter((obj) => obj.name.toLowerCase().includes(query.toLowerCase()));
      if(filtered.length !== 0 && query !== ""){
         setProfiles(filtered);
      }
      else if(query !== ""){
         setProfiles([]);
      }
      else{
         const p = tab === "Companies" ? [...projects] : [...influencers];
         setProfiles(p);
      }
   }

   const getProjectsAndInfluencers = async () => {
      try{
         const status = 'onboarded';
         const type = 'projects, influencers';
         const res = await ProjectService.getProjectAndInfluencerListByStatus(status, type);
         if(res.status === 200){
            const projectList = res.data.projectsList;
            const influencerList = res.data.influencerList;
            setProjects(projectList);
            setInfluencers(influencerList);
            if(tab === "Companies"){
               setProfiles(projectList);
            }
            else{
               setProfiles(influencerList);
            }
         }
      }
      catch(error){
         alert(error);
      }
   }

   useEffect(() => {
      getProjectsAndInfluencers();
   }, [])


   const removeItemFromStorage = () => {
      removeItem("project_id");
      removeItem("influencer_id");
      navigate("/")
   }

   const handleChangeTab = (tab) => {
      setTab(tab);
      tab === "Companies" ? setProfiles(projects) : setProfiles(influencers);
   }

   return(
      <>
         <div className='main-container search-profile-page'>
            <Header 
               transparentBG={true}
               title="Search Profiles"
               onHandleClick={removeItemFromStorage}
            />
            <div className='manage-onborarding-tabs'>
               {
                  Tabs.map((item, ind) => (
                     <div 
                        key={ind}
                        className={`tab ${item === tab ? 'active' : ''}`}
                        onClick={() => handleChangeTab(item)}
                     > 
                        {item} 
                     </div>
                  ))
               }
            </div>
            
            <div className='search-input-wrapper'>
               <img src={SearchIcon} alt='' />
               <input 
                  type="text"
                  value={search}
                  placeholder={tab === 'Companies' ? 'Search for onboarded projects' : 'Search for onboarded Influencers'}
                  className='search-input'
                  onChange={(e) => handleOnSearchChange(e.target.value)}
               />
            </div>

            {
               tab === "Companies" ? 
               <>
                  {
                     profiles.length > 0 ? profiles?.map((item, ind) => (
                        <SearchProfileCard 
                           data={item}
                           selectedTab={tab}
                        />
                     )) 
                     :
                     <div>No Companies found.</div>
                  }
               </>
               : 
               <>
                  {
                     influencers.length > 0 ? influencers.map((item, ind) => (
                        <SearchProfileCard 
                           data={item}
                           selectedTab="Influencer"
                        />
                     )):
                     <div>No influencer found.</div>
                  }
               </>
            }
         </div>
      </>
   )
}
export default SearchProfile;