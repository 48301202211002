import "./index.scss";
import { useState } from "react";
import { ReactComponent as RightArrow } from "@assets/icons/ProjectProfile/right-arrow.svg";

const Tabs = [
  "Experience",
  "Prices",
  "Others",
]

const InfluencerDetails = ({ influencer, editable, handleChange }) => {

  const [tab, setTab] = useState(Tabs[0]);

  return(
    <>
      <div className='d-flex tabs'>
        {Tabs.map((item, index) => (
          <div 
            className={`tab ${item === tab ? "active" : ""}`} 
            key={index}
            onClick={() => setTab(item)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='tab-content profile-page-px'> 
        {tab === "Experience" && (
          <>
            <textarea 
              type='text'
              className='profile-edit-input textarea experience'
              onChange={(e) => handleChange(e.target.value, "experince")}
              value={influencer.experince}
              placeholder="Experience"
              disabled={editable}
            />
            
            <button 
              className='common-button margin-top-14'
            >
              Contact {influencer.name}
              <RightArrow />
            </button>
          </>
        )}
        {tab === "Prices" && (
          influencer?.prices.map((item, ind) => (
            <>
              <div className='platform-name'>{item.platform}</div>
              {
                item.platform === 'twitter' &&
                  <ul className='prices'>
                    <li>For 1 to 3 Tweets : &nbsp; {item.tweet1to3}</li>
                    <li>For 4 to 6 Tweets : &nbsp; {item.tweet4to6}</li>
                    <li>For 8 to 10 Tweets : &nbsp; {item.tweet8to10}</li>
                  </ul>
              }
            </>
          ))
        )}
        {tab === "Others" && (
          <ul className='other-info'>
            <li>
              <div className='label'>&nbsp;Email:</div>
              <div> &nbsp; {influencer.email}</div>
            </li>
            <li>
              <div className='label'>&nbsp;Payment Method:</div>
              <div> &nbsp; {influencer.paymentMethod}</div>
            </li>
            <li>
              <div className='label'>&nbsp;Known online as:</div>
              <div> &nbsp; {influencer.knownOnlineAs}</div>
            </li>
            <li>
              <div className='label'>&nbsp;Niche in crypto space:</div>
              <div> &nbsp; {influencer.cryptoSpace}</div>
            </li>
            <li>
              <div className='label'>&nbsp;Prefered Communication Channel:</div>
              <div> &nbsp; {influencer.communicationChannel}</div>
            </li>
          </ul>
        )}
      </div>
    </>
  )
}
export default InfluencerDetails;