import "./index.scss";

const QuestionWithAnswer = ({ data, index }) => {
  return(
    <div className='d-flex question-with-answer'>
      <div className='question-number'>{index + 1}.</div>
      <div>
        <div className='question'>
          {data.question}
        </div>
        <div className='answer'>
          {data.answer}
        </div>
      </div>
    </div>
  )
}
export default QuestionWithAnswer;