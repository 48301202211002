import "./index.scss";

const TerminationModal = ({ showModal, setModal, reasonForTermination, setReasonForTermination, setTermination }) => {

  const terminateCapmaign = () => {
    setModal(false);
    setTermination(true);
  }

  return(
    <div className={`custom-modal termination-modal ${showModal ? 'show-modal' : ''}`}>
      <div className='modal-card-wrapper'>
        <div className='title'>
          Reason for Termination
        </div>

        <textarea 
          type='text'
          onChange={(e) => setReasonForTermination(e.target.value)}
          palceholder='Enter reason for termination'
        />

        <div className='d-flex margin-top-18'>
          <button
            type="button"
            className='cancel-button'
            onClick={() => setModal(false)}
          >
            Cancle
          </button>

          <button
            type="button"
            className='terminate-button'
            onClick={terminateCapmaign}
          >
            Terminate
          </button>
        </div>
      </div>
    </div>
  )
}
export default TerminationModal;