import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "@components/common/Header";
import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import CustomEventCalender from "@components/common/CustomEventCalender";
// import moment from 'moment';

const meetings = [
   {
      name:'Unore',
      time:'09:46 AM',
      logo: '',
      meetingLink: '',
   },
   {
      name:'Unore',
      time:'09:46 AM',
      logo: '',
      meetingLink: '',
   },
   {
      name:'Unore',
      time:'09:46 AM',
      logo: '',
      meetingLink: '',
   },
]

const MeetingCalender = () => {

   const navigate = useNavigate();
   const [selectedDate, setSelectedDate] = useState(new Date());
   const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1": [
        "Mon May 29 2023 22:36:39 GMT+0530 (India Standard Time)",
        "Thu May 30 2023 22:36:39 GMT+0530 (India Standard Time)",
      ],
    }
  ];

   const joinMeeting = (meetingLink) => {
      window.open(meetingLink, '_blank');
      navigate('/project-onboarding')
   }
   
   

   return(
      <div className='main-container'>
         <Header 
            onHandleClick={() => navigate("/")}
         />
         <div className='meeting-calender-page'>
            <div className='title'>
               Meeting Calendar
            </div>

            <div className='event-calender'>
               <CustomEventCalender 
                  date={selectedDate}
                  setDate={setSelectedDate}
                  highlightDates={highlightWithRanges}
               />
            </div>

            <div className='selected-date'>
               17th August, 2025
            </div>

            {
               meetings?.map((item, ind) => (
                  <div
                     className='meeting-details-card'
                     key={ind}
                  >
                     <div className='d-flex align-items-center'>
                        <img src={item.logo ? item.logo : DummyProfileImg} alt='' className='logo' />
                        <div>
                           <div className='company-name'>
                              {item.name}
                           </div>
                           <div className='time'>
                              {item.time}
                           </div>
                        </div>
                     </div>

                     <button 
                        onClick={() => joinMeeting('https://www.google.com')}
                        className='join-button'
                     >
                        Join
                     </button>
                  </div>
               ))
            }
         </div>
      </div>
   )
}
export default MeetingCalender;