import "./projectDetails.scss";
import { useState } from "react";
import downloadFile from "@utils/downloadFile";

const Tabs = [
  "About",
  "Whitepaper",
  "Funding",
  "Dummy"
]

const info = {
  about : "Davis Stanton is a Bitcoin analyst most popular for his forecasts and analyses. This New Zealand-born commentator and speculator is one of the biggest voices in the crypto space because of his predictions using in-house indicators he created himself. On his feed, he often tweets the latest charts along with his thoughts and readings of the same.Willy Woo is a Bitcoin analyst most popular for his forecasts and analyses.",
  whitepaper : "Davis Stanton is a Bitcoin analyst most popular for his forecasts and analyses. This New Zealand-born commentator and speculator is one of the biggest voices in the crypto space because of his predictions using in-house indicators he created himself. On his feed.",
  funding : "Davis Stanton is a Bitcoin analyst most popular for his forecasts and analyses. This New Zealand-born commentator and speculator is one of the biggest voices in the crypto space because of his predictions using in-house indicators he created himself. On his feed.he often tweets the latest charts along with his thoughts and readings of the same.",
  dummy : "Davis Stanton is a Bitcoin analyst most popular for his forecasts and analyses. This New Zealand-born commentator and speculator is one of the biggest voices in the crypto space because of his predictions using in-house indicators he created himself. On his feed."
}

const ProjectDetails = ({ project, editable, handleChange }) => {

  const [tab, setTab] = useState(Tabs[0]);



  return(
    <>
      <div className='d-flex tabs'>
        {Tabs.map((item, index) => (
          <div 
            className={`tab ${item === tab ? "active" : ""}`} 
            key={index}
            onClick={() => setTab(item)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='tab-content profile-page-px'> 
        {tab === "About" && (
          <>
            <textarea 
              type='text'
              className='profile-edit-input textarea desc'
              onChange={(e) => handleChange(e.target.value, "aboutProject")}
              value={project.aboutProject}
              placeholder="About project"
              disabled={editable}
            />
          </>
        )}
        {tab === "Whitepaper" && 
          <>
            <div className='desc' onClick={() => downloadFile(project.whitepaper, "whitepaper")}>Download Whitepaper</div>
            <div className='desc' onClick={() => downloadFile(project.pitchdeck, "pitchdeck")}>Download pitchdeck</div>
          </>
        }
        {tab === "Funding" && (
          <div className='desc'>{info.funding}</div>
        )}
        {tab === "Dummy" && (
          <div className='desc'>{info.dummy}</div>
        )}
      </div>

    </>
  )
}
export default ProjectDetails;