import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DummyProfileImg from '@assets/icons/Common/dummy-profile.png';
import moment from "moment";
import { setItem } from "@utils/getAndSetDataToStorage";

const ManageProjectOnboarding = ({ data, selectedTab }) => {
  const { name, id, deepLinkSentOn, onBoardingStatus, logo } = data;

  const [nextLink, setNextLink] = useState("");

  const saveData = () => {
    if(selectedTab === 'Companies Onboarding'){
      setItem("project_onboarding_details", JSON.stringify(data));
      setItem("project_id", id);
    }
    else{
      setItem("onboarding_influencer_details", JSON.stringify(data));
      setItem("influencer_id", id);
    }
  }
  
  const date = moment(deepLinkSentOn);
  const formattedDate = date.format('DD MMM YYYY');

  useEffect(() => {

    let nextLink = '';
    if(onBoardingStatus === 'pending'){
      nextLink = selectedTab === 'Companies Onboarding' ? '/project-onboarding' : '/influencer-onboarding';
    }
    else{
      nextLink = selectedTab === 'Companies Onboarding' ? '/project-profile' : '/influencer-profile';
    }
    setNextLink(nextLink);

  }, [onBoardingStatus, selectedTab])

  return(
    <Link 
      to={nextLink} 
      className='onboarding-detail-card'
      onClick={saveData}
    >
      <div className='d-flex align-items-center'>
        <img src={logo ? logo : DummyProfileImg} alt='' className='logo' />
        <div>
          <div className='name'>
            {name}
          </div>
          <div className='date'>
            Sent on - {formattedDate}
          </div>
        </div>
      </div>
      <div className={`question-status ${onBoardingStatus === 'pending' ? 'pending' : ''}`}>
        {onBoardingStatus === 'pending' ? 'Pending' : 'Answers Recieved'}
      </div>
    </Link>
  )
}
export default ManageProjectOnboarding;