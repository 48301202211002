import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserServices from "@services/user";
import Auth from "@services/auth";
import { setItem, getItem } from "@utils/getAndSetDataToStorage";
import { toast } from 'react-toastify';
import RequestNotificationPermission from "@utils/getNotificationPermission";

const SignIn = () => {

   const navigate = useNavigate();
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [installPrompt, setInstallPrompt] = useState(null);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      let userWasLoggedIn = false;
      const getProfileDetails = getItem("user_profile", true)

      if (getProfileDetails) {
         userWasLoggedIn = true;
      }

      const handleBackButton = (event) => {
         event.preventDefault();

         if (userWasLoggedIn) {
            navigate(-1);
         } else {
            window.close();
         }
      };

      window.addEventListener('popstate', handleBackButton);

      return () => {
         // Remove the event listener when the component unmounts
         window.removeEventListener('popstate', handleBackButton);
      };
   }, [navigate]);

   useEffect(() => {
      RequestNotificationPermission()
   }, [])

   const SubmitLogin = async () => {
      setLoading(true);
      if(email !== '' && password !== ''){
         Auth.login(email, password).then((response) => {
            setItem("user_id", response.uid);
            getUserProfile(response);
         }).catch((errorMessage) => {
            toast.error(errorMessage);
            console.log(errorMessage);
            setLoading(false);
         })
      }
   }
   

   const getUserProfile = async (res) => {
      const id = res.uid;
      const role = 'admin'
      await UserServices.getUser(id, role).then((response) => {
         const data = response.data;
         if(data.status === true && res.email === data.data.email){
            setItem("user_profile", JSON.stringify(data.data));
            navigate('/')
         }
         else{
            setEmail('');
            setPassword('');
            toast.error(data.message);
            localStorage.removeItem("x_army_auth_token")
            setLoading(false);
         }
      })
      .catch((error) => {
         toast.error(error.response.data.message);
         console.log(error.response);
      });

      setLoading(false);
   }

     

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
    };
  }, []);

   const handleInstallPrompt = (event) => {
      event.preventDefault();
      setInstallPrompt(event);
   };

   const handleInstallClick = () => {
      if (installPrompt) {
         installPrompt.prompt();
         installPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
               console.log('PWA installation successful!');
            }
            setInstallPrompt(null);
         });
      }
   };

   return(
      <div className='auth-page sign-in-page'>

         <div className='bottom-bg'></div>
         <div className="wrapper">
            <div className='logo-wrapper'></div>

            <div className='welcome-title'>Welcome Back!!! </div>
            <div className='title'> Please sing in to continue </div>

            <input 
               type='email'
               className='auth-input'
               onChange={(e) => {setEmail(e.target.value)}}
               value={email}
               placeholder='Enter your email'
            />
            <input 
               type='password'
               className='auth-input'
               onChange={(e) => {setPassword(e.target.value)}}
               value={password}
               placeholder='Enter your password'
            />

            <button 
               className='signin-button common-button position-relative margin-top-20'
               disabled={email === '' || password === '' || loading}
               onClick={SubmitLogin}
            >
               Sign In
            </button>

            {
               installPrompt &&
               <button 
                  className='signin-button common-button position-relative margin-top-20'
                  onClick={handleInstallClick}
               >
                  Install this app
               </button>
            }
         </div>
      </div>
   )
}
export default SignIn;