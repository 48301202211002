import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from '@components/common/Header';
import MeetingDetailCard from "@components/common/MeetingDetailCard";
// import AnsweredQuestion from '@components/ProjectOnboardingDetails/AnsweredQuestions';
import QuestionsWrapper from '@components/ProjectOnboardingDetails/QuestionsWrapper';
import { getItem, removeItem } from "@utils/getAndSetDataToStorage";
import ProjectService from "@services/project";
import moment from "moment";
import { toast } from 'react-toastify';

const ProjectOnboardingDetails = () => {

  const navigate = useNavigate();
  const [projectDetails, setProjectDetails] = useState({});
  const [tab, setTab] = useState('onboarding');
  const [questionList, setQuestionList] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [campaignQuestions, setCampaignQuestions] = useState([]);
  const [campaingAnswered, setCampaignAnswered] = useState([]);
  const [campDuration, setCampDuration] = useState([]);

  useEffect(() => {
    const onBoardingDetails = getItem('project_onboarding_details', true);
    const answered  = onBoardingDetails.projectQuestions.filter((obj) => obj.answer !== '');
    const questions = onBoardingDetails.projectQuestions.filter((obj) => obj.answer === '');
    
    if(onBoardingDetails.withCampaign){
      const campaingAnswered = onBoardingDetails.campaignQuestions.filter((obj) => obj.answer !== '' && obj.questionId !== 'no of tweets');
      const campaignQuestions = onBoardingDetails.campaignQuestions.filter((obj) => obj.answer === '' || obj.questionId === 'no of tweets');
      const camp_dur = onBoardingDetails.campaignQuestions.find((obj) => obj.questionId === 'campaign duration');

      setCampDuration(camp_dur)
      setCampaignQuestions(campaignQuestions);
      setCampaignAnswered(campaingAnswered);
    }
    setAnsweredQuestions(answered);
    setQuestionList(questions);
    setProjectDetails(onBoardingDetails);
  }, [])

  const checkInputs = () => {
    const isAnsweredFilled = answeredQuestions.every((obj) => obj.answer !== '');
    const isQuestionsFilled = questionList.every((obj) => obj.answer !== '');
    if(projectDetails.withCampaign){
      const camp_answered = campaingAnswered.every((obj) => obj.answer !== '');
      const camp_questions = campaignQuestions.every((obj) => obj.answer !== '');

      if(isAnsweredFilled && isQuestionsFilled && camp_answered && camp_questions) return false
      else return true;
    }
    else{
      if(isAnsweredFilled && isQuestionsFilled) return false
      else return true;
    }
  }

  useEffect(() => {
    if (projectDetails.withCampaign) {
      const camp_dur = campaingAnswered.find((obj) => obj.questionId === 'campaign duration');
      setCampDuration((prevObj) => {
        const newObj = { ...prevObj };
        newObj.answer = camp_dur.answer;
        return newObj;
      });
    }
  }, [campaingAnswered, projectDetails.withCampaign]);

  const removeDataFromStorage = () => {
    removeItem("project_onboarding_details");
    removeItem("project_id");
    navigate('/manage-onboarding')
  }

  const createProfile = async () => {

    const allQuestions = [...answeredQuestions, ...questionList];
    const allCampQuestions = [...campaingAnswered, ...campaignQuestions];
    const data = {
      projectId: projectDetails.id,
      projectQuestions: JSON.stringify(allQuestions)
    }
    if(projectDetails.withCampaign){
      data.campaignId = projectDetails.campaignId;
      data.campaignQuestions = JSON.stringify(allCampQuestions);
    }
    console.log(allCampQuestions)
    await ProjectService.updateProjectProfile(data).then((res) => {
      if(res.data.status){
        navigate("/project-profile")
      }
      else{
        toast.error(res.data.message);
      }
    })
    .catch((e) => {
      const errors = e.response.data.message;
      toast.error(errors)
    })
  }

  return(
    <div className='main-container'>
      <Header 
        onHandleClick={removeDataFromStorage}
      />

      <div className='onboarding-details-page'>
        
        <MeetingDetailCard 
          sentOn={projectDetails?.deepLinkSentOn}
          name={projectDetails?.name}
          meetingDate='24th Oct 2025'
          profileImg=''
        />
        
        <div className='title'>
          Onboarding Details
        </div>

        {
          projectDetails.withCampaign &&
          <>
            <div className='d-flex align-items-center margin-bottom-25'>
              <div 
                className={`onboarding-marketing-tab ${tab === 'onboarding' ? ' active' : ''}`}
                onClick={() => setTab('onboarding')}
              >
                Onboarding
              </div>
              <div 
                className={`onboarding-marketing-tab ${tab === 'marketing' ? ' active' : ''}`}
                onClick={() => setTab('marketing')}
              >
                Marketing
              </div>
            </div>
          </>
        }

        {
          tab === 'onboarding' &&
          <>
            {
              answeredQuestions?.length > 0 &&
              <QuestionsWrapper 
                questions={answeredQuestions}
                setQuestion={setAnsweredQuestions}
                title="Questions Answered"
              />
            }

            {
              questionList?.length > 0 &&
              <QuestionsWrapper 
                questions={questionList}
                setQuestion={setQuestionList}
                title="Questions Pending"
              />
            }
          </>
        }

        {
          tab === 'marketing' &&
          <>
            {
              campaingAnswered?.length > 0 &&
              <QuestionsWrapper 
                questions={campaingAnswered}
                setQuestion={setCampaignAnswered}
                title="Questions Answered"
              />
            }

            {
              campaignQuestions?.length > 0 &&
              <QuestionsWrapper 
                questions={campaignQuestions}
                setQuestion={setCampaignQuestions}
                title="Questions Pending"
                campaignDuration={campDuration}
              />
            }
          </>
        }


        <button
          onClick={createProfile}
          disabled={checkInputs()}
          className='common-button margin-top-30'
        >
          Create Profile
        </button>
      </div>
    
    </div>
  )
}
export default ProjectOnboardingDetails;