import { messaging } from '../services/firebase';
import { getToken } from "firebase/messaging";

const RequestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    alert("This browser does not support notification")
  }
  else{
    const permission = await Notification.requestPermission()
    if(permission === 'granted'){
      const token = await getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY});
      console.log(token, " ____token")
      localStorage.setItem("user_notification_token", token);
    }
    else if(permission === 'denied'){
      alert("Please allow us to send notification.")
    }
  }
}
export default RequestNotificationPermission;